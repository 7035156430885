import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EasetypesService {

  constructor() { }

  selectEaseType(type) {
    let ease;
    switch (type) {
      case 'bounce':
        ease = 'bounce.out';
        break;
      case 'bouncein':
        ease = 'bounce.in';
        break;
      case 'elastic':
        ease = 'elastic.out(1, 0.3)'
        break;
      case 'elasticin':
        ease = 'elastic.in(1, 0.3)'
        break;
      case 'circle':
        ease = 'circ.out'
        break;
      case 'circlein':
        ease = 'circ.in'
        break;
      case 'sine':
        ease = 'sine.out'
        break;
      case 'sinein':
        ease = 'sine.in'
        break;
      case 'over':
        ease = 'back.out(1.7)'
        break;
      case 'power1':
        ease = 'power1.out'
        break;
      case 'power1in':
        ease = 'power1.in'
        break;
      case 'power2':
        ease = 'power2.out'
        break;
      case 'power3':
        ease = 'power3.out'
        break;
      case 'easy':
        ease = 'power0.out'
        break;
      case 'slowmotion':
        ease = 'slow(0.7, 0.7, false)'
      case 'rough':
        ease = 'rough'
      case 'steps':
        ease = 'steps'
      case 'none':
        ease = 'none'
      default:
        ease = 'none';
    }
    return ease
  }


  R(min, max) { return min + Math.random() * (max - min) };
}
