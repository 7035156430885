import { Injectable } from "@angular/core";
import * as THREE from "three";
import { threedanimation } from "../videocreator.model";


@Injectable({
    providedIn: 'root'
})


export class RotateHelperService {
    targetRotationX = 0.5;
    targetRotationOnMouseDownX = 0;

    targetRotationY = 0.2;
    targetRotationOnMouseDownY = 0;

    mouseX = 0;
    mouseXOnMouseDown = 0;

    mouseY = 0;
    mouseYOnMouseDown = 0;

    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;

    slowingFactor = 0;
    onMouseMove: any;

    group: any;
    element: threedanimation;
    horizontal: boolean;
    x: any;
    y: any;
    z: any;


    setRotateListener(group: any, element: threedanimation, x, y, z) {
        console.log( x, y, z)
        this.group = group;
        this.element = element;

        this.x = x;
        this.y = y;
        this.z = z;
      

        let docset = document.getElementById('canvasthreed');
        this.render(group);
        docset.addEventListener('mousedown', this.onDocumentMouseDown, false);
    }

    render(group) {
        //let group = this.scene
        this.targetRotationY = this.targetRotationY * (1 - this.slowingFactor);
        this.targetRotationX = this.targetRotationX * (1 - this.slowingFactor);

        if (this.x){
            //this.rotateAroundWorldAxis(group, new THREE.Vector3(0, 1, 0), this.targetRotationX);
            this.rotateAroundObjectAxis(group, new THREE.Vector3(0, 1, 0), this.targetRotationX);
        } 
        
        if (this.y){
            //this.rotateAroundWorldAxis(group, new THREE.Vector3(1, 0, 0), this.targetRotationY);
            this.rotateAroundObjectAxis(group, new THREE.Vector3(1, 0, 0), this.targetRotationY);
        }

                
        if (this.z){
            //this.rotateAroundWorldAxis(group, new THREE.Vector3(0, 0, 1), this.targetRotationY);
            this.rotateAroundObjectAxis(group, new THREE.Vector3(0, 0, 1), this.targetRotationY);
        }

    }

    rotateAroundObjectAxis(object, axis, radians) {
        var rotationMatrix = new THREE.Matrix4();
        rotationMatrix.makeRotationAxis(axis.normalize(), radians);
        object.matrix.multiply(rotationMatrix);
        object.rotation.setFromRotationMatrix(object.matrix);
    }

    rotateAroundWorldAxis(object, axis, radians) {
       // console.log(object, axis, radians);
        var rotationMatrix = new THREE.Matrix4();
        rotationMatrix.makeRotationAxis(axis.normalize(), radians);
        rotationMatrix.multiply(object.matrix);                       // pre-multiply
        object.matrix = rotationMatrix;
        object.rotation.setFromRotationMatrix(object.matrix);
    }


    onDocumentMouseDown = (e) => {
        let docset = document.getElementById('canvasthreed');
        docset.addEventListener('mousemove', this.onDocumentMouseMove,  false);
        docset.addEventListener('mouseup', this.onDocumentMouseUp, false);
        docset.addEventListener('mouseout', this.onDocumentMouseOut, false);

        this.mouseXOnMouseDown = e.clientX - this.windowHalfX;
        this.targetRotationOnMouseDownX = this.targetRotationX;
        this.mouseYOnMouseDown = e.clientY - this.windowHalfY;
        this.targetRotationOnMouseDownY = this.targetRotationY;
    }

    onDocumentMouseMove = (e) => {
        this.mouseX = e.clientX - this.windowHalfX;
        this.targetRotationX = (this.mouseX - this.mouseXOnMouseDown) * 0.0008; // was 0.00025
        this.mouseY = e.clientY - this.windowHalfY;
        this.targetRotationY = (this.mouseY - this.mouseYOnMouseDown) * 0.0008;
        this.render(this.group);
    };


    onDocumentMouseUp = (e) => {
        let docset = document.getElementById('canvasthreed');
        docset.removeEventListener('mousemove', this.onDocumentMouseMove, false);
        docset.removeEventListener('mouseup', this.onDocumentMouseUp, false);
        docset.removeEventListener('mouseout', this.onDocumentMouseOut, false);
        //console.log(this.group)
        this.element.rotationx = this.group.rotation.x;
        this.element.rotationy = this.group.rotation.y;
        this.element.rotationz = this.group.rotation.z;
    }

    onDocumentMouseOut = (e) => {
        let docset = document.getElementById('canvasthreed');
        docset.removeEventListener('mousemove', this.onDocumentMouseMove, false);
        docset.removeEventListener('mouseup', this.onDocumentMouseUp, false);
        docset.removeEventListener('mouseout', (e) => {this.onDocumentMouseOut}, false);
    }

}





