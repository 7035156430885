import { Injectable } from '@angular/core';
import { Canvas } from '../videocreator.model';

@Injectable({
  providedIn: 'root'
})
export class CodegeneratorService {

  private baseUrl = 'https://app.enydea.com';
  //private baseUrl = 'http://localhost:4200';
  private baseApi = 'http://localhost:3000';

  constructor() { }


  // 1. create HTML
  async createHTML(canvas: Canvas, element) {
    //add styles
    let htmlstring = this.createCSS();

    // add elements
    if (element.type === 'image') {
      let elementstring = this.createImage(element);
      htmlstring = htmlstring + elementstring;
    }
    if (element.type === 'vector') {
      let elementstring = this.createVector(element);
      htmlstring = htmlstring + elementstring;
    }


    //add libraries and script
    htmlstring = htmlstring + this.createAnimations(element, canvas);

    return htmlstring;
  }



  createCSS() {
    let stylescript = `
    <link rel="stylesheet" href="${this.baseUrl}/assets/js/addanimation.css">
    `;
    return stylescript;
  }

  createAnimations(elementsource, canvassource) {

    const element = JSON.stringify(elementsource);
    const canvas = JSON.stringify(canvassource);
    let animationscript = `

      <script type="module">
      import gsap from "https://app.enydea.com/assets/js/all.js";
      import {addEffect} from "https://app.enydea.com/assets/js/addanimation.js";
        // wait for page to load
        window.addEventListener('load', function () {
          const primairytimeline = gsap.timeline({ paused: true, reversed: true });
          const canvas = ${canvas};
          const element = ${element};
          addEffect(element, canvas, primairytimeline);
          primairytimeline.play(0, true);
        })
      </script>
      `;
    return animationscript;
  }

  createVector(element): any {
    let elementstyle = JSON.stringify(element.style);
    elementstyle = this.cleanUpJSON(elementstyle);

    let vector = `
    <div 
    width="element.style.width" height="element.style.height"
    style="${elementstyle}" id="${element.id}">
      <div style="height: 100%; width: 100%; cursor: pointer;">
      ${element.svgcombi}
      </div>

</div>
`;
    return vector
  }


  createImage(element): any {
    let elementstyle = JSON.stringify(element.style);
    elementstyle = this.cleanUpJSON(elementstyle)
    let image = `
    <div id="${element.id}" style="${elementstyle}"
    width="${element.style.width}" height="${element.style.height}">
     <svg xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
           id="${element.id}crop" width="100%" height="100%"
             style="fill:transparent; stroke:black; stroke-width: 2px;" viewBox="0 0 500 500">
          <defs><clipPath id="${element.id}cropclip">
               <path d="${element.clippath}" id="${element.id}croppath" />
                </clipPath>
              </defs>
            </svg>
          `;

    if (element.src && element.cliparray.length === 0) {
      image = image + `<img style="width: 100%; height: 100%; opacity: ${element.opacity}"
      src="${element.src}" `
    }


    let classstring = '"'
    if (element.blur && element.grey) { classstring = 'class="imgblur imggreyclass"' }
    else if (element.grey) { classstring = 'class="imggreyclass" ' }
    else if (element.blur) { classstring = 'class="imgblur" ' }
    // set class 
    image = image + classstring + '>';

    if (element.cliparray.length > 0) {
      element.cliparray.forEach(clippart => {
        let clippartstyle = JSON.stringify(clippart.style);
        clippartstyle = this.cleanUpJSON(clippartstyle)
        image = image + `<div>
        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
            <img src=${element.src} style="${clippartstyle}"
                id="${clippart.id}">
                </div>
        </div>`
      });
    }
    image = image + `</div> `;
    return image
  }




  cleanUpJSON(elementstyle) {
    elementstyle = elementstyle.replace(/"/g, '');
    elementstyle = elementstyle.replace(/{/g, '');
    elementstyle = elementstyle.replace(/}/g, '');
    elementstyle = elementstyle.replace(/,/g, '; ');
    return elementstyle;
  }

}
