<!-- [style.width]="selectedForm.width + selectedForm.wform"
    [style.height]="selectedForm.height + selectedForm.hform -->
<div id="enydea-form-container">
    <div *ngIf="selectedForm && sendok === false" [style.color]="selectedForm.textcolor" [style.top]="top"
        [style.left]="left" [style.position]="position" [style.background-color]="selectedForm.color"
        class="formstyle rootenydea" id="enydeaFOdiv" [style.transform]="'scale('+zoomfactor+')'"
        [style.font-family]="selectedForm.font">
        <form [formGroup]="reactiveForm" id="formroot">
            <div *ngFor="let row of finalform; let i = index;">
                <div *ngIf="row.length === 0" style="width: 100%" id="template"></div>

                <div fxLayout="row" id="element" fxLayout.xs="column" fxLayoutGap="10px">
                    <div *ngFor="let element of row; let i2 = index" style=" width: 100%">
                      
                            <div fxFlex *ngIf="element.type === 'formcheckbox'" class="formelement">
                                <mat-checkbox [disabled]="busy"
                                    [formControlName]="setFormControl(element.valuetype, element.required, element.fieldid)">
                                    {{element.text}}</mat-checkbox>
                            </div>

                            <div fxFlex *ngIf="element.type === 'formbutton'" class="formelement"
                                [fxLayoutAlign]="element.align">
                                <button [style.color]="element.textcolor" [style.background-color]="element.color"
                                    (click)="buttonAction(element.action, element.url)" [disabled]="!reactiveForm.valid || busy"
                                    [style.width]="element.width + 'px'" mat-raised-button>{{element.text}}</button>
                            </div>

                            <div fxFlex *ngIf="element.type === 'formtext'" class="formelement">
                                <label [style.font-size]="element.fontsize">{{element.text}}</label>
                            </div>

                            <!-- [(ngModel)]="element.value" -->
                            <div fxFlex *ngIf="element.type === 'forminput'" class="formelement">
                                <mat-form-field style="width: 100%;" [appearance]="element.appearance" [disabled]="busy">
                                    <mat-label>{{element.text}}</mat-label>
                                    <input matInput [placeholder]="element.text" [type]="element.valuetype"
                                        [errorStateMatcher]="matcher"
                                        [formControlName]="setFormControl(element.valuetype, element.required, element.fieldid)">
                                </mat-form-field>
                                <mat-error *ngIf="checkForErrors(element.fieldid) && element.valuetype === 'email'">
                                    Email is <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="checkForErrors(element.fieldid) && element.valuetype !== 'email'">
                                    This field is <strong>required</strong>
                                </mat-error>
                                <label>{{element.description}}</label>
                            </div>

                            <div fxFlex *ngIf="element.type === 'forminputtextfield'" class="formelement">
                                <mat-form-field style="width: 100%;" [appearance]="element.appearance" [disabled]="busy">
                                    <mat-label>{{element.text}}</mat-label>
                                    <textarea matInput [placeholder]="element.text" type="text" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                        [errorStateMatcher]="matcher"
                                        [formControlName]="setFormControl(element.valuetype, element.required, element.fieldid)">
                            </textarea>
                                </mat-form-field>
                                <mat-error *ngIf="checkForErrors(element.fieldid) && element.valuetype !== 'email'">
                                    This field is <strong>required</strong>
                                </mat-error>
                                <label>{{element.description}}</label>
                            </div>

                            <div fxFlex *ngIf="element.type === 'formselectionfield'" class="formelement">
                                <mat-form-field style="width: 100%;" [appearance]="element.appearance" [disabled]="busy">
                                    <mat-label>{{element.text}}</mat-label>
                                    <mat-select name="item" [placeholder]="element.text"  (selectionChange)="checkOption()"
                                        [formControlName]="setFormControl(element.valuetype, element.required, element.fieldid)"
                                        [errorStateMatcher]="matcher">
                                        <mat-option *ngFor="let item of element.selectionoptions" [value]="item">
                                            <label>{{item}}</label>
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkForErrors(element.fieldid)">
                                        This field is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex *ngIf="element.type === 'formimage'" class="formelement"
                                fxLayoutAlign="center center">
                                <img [src]="element.src" [width]="element.width" [height]="element.height">
                            </div>

                            <div fxFlex *ngIf="element.type === 'divider'" class="formelement">
                                <mat-divider></mat-divider>
                            </div>
                       
                    </div>

                </div>

            </div>
        </form>
        <!-- formControlName="recaptchaenydea" -->
        <!-- <div style="height: 100px">
        <re-captcha (resolved)="resolved($event)" siteKey="6LeYLFQlAAAAAIKtH66USJj2DteNgBU3PYXRwbyT">
        </re-captcha>
    </div> -->
    </div>
    <!-- [style.width]="selectedForm.width + selectedForm.wform"
    [style.height]="selectedForm.height + selectedForm.hform" -->
    <div *ngIf="sendok" [style.color]="selectedForm.textcolor" [style.background-color]="selectedForm.color"
        class="formstyle" fxLayoutAlign="center center">
        <h4>{{selectedForm.thankyoutext}}</h4>
        <img [src]="selectedForm.thankyouimgsrc" style="max-height: 200px; max-width: 200px;">
    </div>
</div>