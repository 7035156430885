import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { AppService } from "../app.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Formcheckbox, Formbutton, Formtext, Forminputfield, Formselectionfield, FormImage, FormDivider } from './formsmodel';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { Observable, Subscription } from 'rxjs';

import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatSnackBar } from '@angular/material/snack-bar';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-formobject',
  templateUrl: './formobject.component.html',
  styleUrls: ['./formobject.component.scss']
})
export class FormobjectComponent implements OnInit {


  @Input() videoFormId;
  // private eventsSubscription: Subscription;
  // @Input() events: Observable<void>;

  public position = '';
  public left = '0px';
  public top = '0px';
  public zoomfactor = 1;
  public inputtypes = [
    'color',
    'date',
    'datetime-local',
    'email',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'time',
    'url',
    'week'
  ]

  private verifytoken = true;
  public sendok = false;
  public selectedForm = {} as any;
  public reactiveForm = new UntypedFormGroup({});
  matcher = new MyErrorStateMatcher();
  currentheight: number = 200;
  public finalform = [];
  id: string;
  busy: boolean;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    private service: AppService
  ) {
    this.id = this.route.snapshot.queryParamMap.get("id");
    // if (id) {
    //   this.getFormLayout(id);
    // }

  }

  ngOnInit(): void {
    // if (this.events) {
    // this.eventsSubscription = this.events.subscribe((id) => this.getFormLayout(id));
    // };
    this.getFormLayout(this.id);

  }

  async emitHeight() {
    // change upper iframe size no direct connection due to CORS protection is possible so trigger every second to see if anything changes
    // setInterval(() => {
    await new Promise(resolve => setTimeout(resolve, 300));
    let height = document.getElementById('enydeaFOdiv').offsetHeight + 40;
  
    if (this.currentheight !== height) {
   //   console.log(this.currentheight, height);
      this.currentheight = height;
      window.parent.postMessage(["setHeight", height], "*");
    }
    // }, 1000);
  }

  /* function checks if there are any optional fields and shows it accordingly 
  
  */
  async checkOption() {
    // console.log(this.selectedForm.template)
    let finalform = [];
    for (let i = 0; i < this.selectedForm.template.length; i++) {
      const row = this.selectedForm.template[i];
      finalform.push([])
      for (let y = 0; y < row.length; y++) {
        const formfield = row[y];
        if (formfield.optional) {

          let value = this.reactiveForm.value[formfield.optionalfield];

          if (value === formfield.optionfieldvalue) {
            finalform[i].push(formfield)
          }
          //let option this.findFieldById(formfield.optionalfield);
        } else {
          finalform[i].push(formfield)
        }
      }
    }
  //  console.log(finalform)
    this.finalform = finalform;
    this.emitHeight();
  }

  findFieldById(id) {
    for (let i = 0; i < this.selectedForm.template.length; i++) {
      const row = this.selectedForm.template[i];
      for (let y = 0; y < row.length; y++) {
        const formfield = row[y];

        if (formfield.fieldid === id) {
          return formfield;
        }
      }
    }
  }

  // ngOnDestroy() {
  //   //if (this.eventsSubscription) {
  // //  this.eventsSubscription.unsubscribe();
  //   // }
  // }

  public setFormStyle() {
    // setTimeout(() => {

    let r = document.querySelector('.rootenydea') as any; //Get global root element
   //console.log(r)
    if (r) {
      r.style.setProperty('--myCSS_ValueOne', this.selectedForm.highlight);
      r.style.setProperty('--myCSS_Valuefont', this.selectedForm.font);
      r.style.setProperty('--myCSS_Valuefontsize', this.selectedForm.fontsize);
      r.style.setProperty('--myCSS_suggestioncolor', this.selectedForm.suggestioncolor);
    }
    //  }, 1000);
  }



  public openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000,
      panelClass: "snackbar-class"
    });
  }

  checkForErrors(fieldid) {
    let name = fieldid;
    let error = false;
    if (this.reactiveForm.controls[name].errors && this.reactiveForm.controls[name].touched ) { // 
      error = true;
    } 
    this.emitHeight();
    //console.log(this.reactiveForm.controls[name])
    return error
  }

  getFormLayout(id) {
    this.service.getForm(id).subscribe(form => {
      this.selectedForm = JSON.parse(form);
      this.checkOption();
      this.setFormStyle();
      // unfortunatly runs every second might be due to resize function triggering every second
      //   this.reactiveForm.valueChanges.subscribe(x => {
      //    // console.log(x)
      //   //  this.checkOption();
      // })


      //console.log(this.selectedForm);
      if (this.selectedForm.zoom) {
        // this.setZoom();

      }

    });
  }

  buttonAction(action, url?) {
    //console.log(action, url)
    if (action === 'send form') {
      this.sendForm();
      //console.log(this.reactiveForm)

    }
    if (action === 'open url') {
      this.navigateOut(url)
    }
  }


  // sendForm() {
  //   if (this.reactiveForm.status === 'VALID') {
  //     // this.recaptchaV3Service.execute('sendForm')
  //     //   .subscribe((token: string) => {

  //     //     console.debug(`Token [${token}] generated`);
  //     //   });
  //     let formobject = this.getFormValues();
  //     this.service.sendForm(formobject, this.selectedForm).subscribe(res => {
  //               //if (res === 'success'){
  //               this.sendok = true;
  //               //}
  //   } else {
  //     console.log('validation failed')
  //     this.openSnackBar('Please check your field')
  //   }
  // }

  sendForm() {
    this.busy = true;

    this.recaptchaV3Service.execute('sendForm')
      .subscribe((token: string) => {
        console.debug(`Token [${token}] generated`);
        this.service.sendToken(token).subscribe(
          data => {
            // console.log(data)
            this.verifytoken = data.success;

            if (this.verifytoken) {
              if (this.reactiveForm.status === 'VALID') {
                let formobject = this.getFormValues();
                //console.log(formobject)
                this.service.sendForm(formobject, this.selectedForm).subscribe(res => {
                  //if (res === 'success'){
                  this.sendok = true;
                  this.busy = false;
                  //}
                })
              } else {
                console.log('validation failed');
                this.openSnackBar('Please check your field');
                this.busy = false;
              }
            } else {
              console.log('captcha failed');
              this.openSnackBar('Captcha failed');
              this.busy = false;
            }
          });
      });
  }

  getFormValues() {
    let formarray = [];
    for (let i = 0; i < this.finalform.length; i++) {
      let row = this.finalform[i];
      for (let y = 0; y < row.length; y++) {
        let part = row[y];
        console.log(part)
        console.log(this.reactiveForm.value[part.fieldid])
        let dataset = { type: part.type, text: part.text, value: this.reactiveForm.value[part.fieldid], valuetype: '' };
        if (dataset.type === 'forminput') { dataset.valuetype = part.valuetype }
        if (dataset.value) {
          formarray.push(dataset)
        }
      }
      
    }
    // this.finalform.forEach((element, i) => {
    //   element.forEach((part, i2) => {
    //     console.log(this.reactiveForm.value[part.fieldid])
    //     //let name = 'form' + i + 'item' + i2;
    //     let dataset = { type: part.type, text: part.text, value: this.reactiveForm.value[part.fieldid], valuetype: '' };
    //     if (dataset.type === 'forminput') { dataset.valuetype = part.valuetype }
    //     if (dataset.value) {
    //       formarray.push(dataset)
    //     }
    //   });
    // });
    return formarray
  }

  setFormControl(type, required, id) {
  //  console.log(type, required, id)
    let validators = [];
    if (required) {
      validators.push(Validators.required)
    }
    switch (type) {
      case 'email':
        validators.push(Validators.email)
        let email = new UntypedFormControl('', validators);
        let emailformname = id;
        this.reactiveForm.addControl(emailformname, email);
        return emailformname;
        break;

      default:
        let defaulttype = new UntypedFormControl('', validators);
        let formname = id;
        this.reactiveForm.addControl(formname, defaulttype);
        return formname;
        break;
    }
  }

  // async resolved(captchaResponse: string) {
  //   //console.log(`Resolved response token: ${captchaResponse}`);
  //   await this.sendTokenToBackend(captchaResponse); //declaring the token send function with a token parameter
  // }

  sendTokenToBackend(tok) {
    //calling the service and passing the token to the service
    this.service.sendToken(tok).subscribe(
      data => {
        //console.log(data)
        this.verifytoken = data.success
      }, err => {
        //  console.log(err);
        this.verifytoken = err.success
      }
    );
  }

  navigateOut(url) {
    console.log(url);
    if (url) {
      if (url.indexOf('http') == -1) {
        url = 'https://' + url
      }
      // var iFrameDetection = (window === window.parent) ? false : true;
      // console.log(iFrameDetection)
      if (window.parent) {
        window.parent.location.href = url;

      } else {
        window.location.href = url;
      }
    }
  }


  public setZoom() {
    let y = screen.height;
    let x = screen.width;

    x = window.innerWidth;
    y = window.innerHeight;
    console.log(x, y)

    if (y === 0 && x === 0) {
      x = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      y = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      console.log(x, y)
    }

    let width = parseInt(this.selectedForm.width);
    let height = parseInt(this.selectedForm.height);

    if (this.selectedForm.hform === '%') {
      height = (y / 100) * width
    }

    if (this.selectedForm.wform === '%') {
      width = (x / 100) * height
    }

    if (x < width || y < height) {
      let zoomx = x / width;
      let zoomy = y / height;
      if (zoomx < zoomy) {
        this.zoomfactor = zoomx;
      } else {
        this.zoomfactor = zoomy;
      }
    }

    if (this.zoomfactor !== 1) {
      let scale = this.zoomfactor - 1;
      let w = width / 2;
      let h = height / 2;
      this.left = w * scale + 'px';
      this.top = h * scale + 'px';
      this.position = 'absolute';
    }
    if (this.zoomfactor === 1) {
      this.left = '0px';
      this.top = '0px';
    }
  }


}
