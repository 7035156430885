import { Injectable } from '@angular/core';
import * as THREE from 'three';
import { Mesh } from 'three';
import gsap from "gsap";



@Injectable({
    providedIn: 'root'
})
export class EffectsService {
    mesh: THREE.Group;
    bulbs = [];

    constructor() { }

    private particlesData = [];
    private particlescloud;
    private pointCloud;
    private particlePositions;
    private linesMesh;
    private positions;
    private colors;
    private maxParticleCount = 1000;
    private particleCount = 450;
    private r = 800;
    private rHalf = this.r / 2;


    pitchSegments = 60; //60
    elevationSegments = this.pitchSegments / 2;
    particles = this.pitchSegments * this.elevationSegments
    phase: number = 0;
    side = Math.pow(this.particles, 1 / 3);
    radius = 16; //16
    mousePos = { x: .5, y: .5 };
    id: number;


    private effectController = {
        showDots: true,
        showLines: false,
        minDistance: 150,
        limitConnections: false,
        maxConnections: 17,
        particleCount: 450
    };


    async initLineBox(scene, element) {

        let group = new THREE.Group();
        scene.add(group);
        this.mesh = group;

        const segments = this.maxParticleCount * this.maxParticleCount;

        this.positions = new Float32Array(segments * 3);
        this.colors = new Float32Array(segments * 3);

        const pMaterial = new THREE.PointsMaterial({
            color: element.color,
            size: 3,
            blending: THREE.AdditiveBlending,
            transparent: true,
            sizeAttenuation: false
        });

        this.particlescloud = new THREE.BufferGeometry();
        this.particlePositions = new Float32Array(this.maxParticleCount * 3);

        for (let i = 0; i < this.maxParticleCount; i++) {

            const x = Math.random() * this.r - this.r / 2;
            const y = Math.random() * this.r - this.r / 2;
            const z = Math.random() * this.r - this.r / 2;

            this.particlePositions[i * 3] = x;
            this.particlePositions[i * 3 + 1] = y;
            this.particlePositions[i * 3 + 2] = z;

            // add it to the geometry
            this.particlesData.push({
                velocity: new THREE.Vector3(- 1 + Math.random() * 2, - 1 + Math.random() * 2, - 1 + Math.random() * 2),
                numConnections: 0
            });
        }

        this.particlescloud.setDrawRange(0, this.particleCount);
        this.particlescloud.setAttribute('position', new THREE.BufferAttribute(this.particlePositions, 3).setUsage(THREE.DynamicDrawUsage));

        // create the particle system
        this.pointCloud = new THREE.Points(this.particlescloud, pMaterial);
        group.add(this.pointCloud);
        this.pointCloud.visible = true;

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3).setUsage(THREE.DynamicDrawUsage));
        geometry.setAttribute('color', new THREE.BufferAttribute(this.colors, 3).setUsage(THREE.DynamicDrawUsage));
        geometry.computeBoundingSphere();
        geometry.setDrawRange(0, 0);

        const material = new THREE.LineBasicMaterial({
            vertexColors: true,
            blending: THREE.AdditiveBlending,
            transparent: true,
            color: element.color
        });

        this.linesMesh = new THREE.LineSegments(geometry, material);
        this.linesMesh.visible = true;
        group.add(this.linesMesh);
    }

    async animateLineBox() {
        let vertexpos = 0;
        let colorpos = 0;
        let numConnected = 0;

        for (let i = 0; i < this.particleCount; i++) {
            this.particlesData[i].numConnections = 0;
        }


        for (let i = 0; i < this.particleCount; i++) {

            // get the particle
            const particleData = this.particlesData[i];

            this.particlePositions[i * 3] += particleData.velocity.x;
            this.particlePositions[i * 3 + 1] += particleData.velocity.y;
            this.particlePositions[i * 3 + 2] += particleData.velocity.z;

            if (this.particlePositions[i * 3 + 1] < - this.rHalf || this.particlePositions[i * 3 + 1] > this.rHalf)
                particleData.velocity.y = - particleData.velocity.y;

            if (this.particlePositions[i * 3] < - this.rHalf || this.particlePositions[i * 3] > this.rHalf)
                particleData.velocity.x = - particleData.velocity.x;

            if (this.particlePositions[i * 3 + 2] < - this.rHalf || this.particlePositions[i * 3 + 2] > this.rHalf)
                particleData.velocity.z = - particleData.velocity.z;

            if (this.effectController.limitConnections && particleData.numConnections >= this.effectController.maxConnections)
                continue;

            // Check collision
            for (let j = i + 1; j < this.particleCount; j++) {

                const particleDataB = this.particlesData[j];
                if (this.effectController.limitConnections && particleDataB.numConnections >= this.effectController.maxConnections)
                    continue;

                const dx = this.particlePositions[i * 3] - this.particlePositions[j * 3];
                const dy = this.particlePositions[i * 3 + 1] - this.particlePositions[j * 3 + 1];
                const dz = this.particlePositions[i * 3 + 2] - this.particlePositions[j * 3 + 2];
                const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

                if (dist < this.effectController.minDistance) {

                    particleData.numConnections++;
                    particleDataB.numConnections++;

                    const alpha = 1.0 - dist / this.effectController.minDistance;

                    this.positions[vertexpos++] = this.particlePositions[i * 3];
                    this.positions[vertexpos++] = this.particlePositions[i * 3 + 1];
                    this.positions[vertexpos++] = this.particlePositions[i * 3 + 2];

                    this.positions[vertexpos++] = this.particlePositions[j * 3];
                    this.positions[vertexpos++] = this.particlePositions[j * 3 + 1];
                    this.positions[vertexpos++] = this.particlePositions[j * 3 + 2];

                    this.colors[colorpos++] = alpha;
                    this.colors[colorpos++] = alpha;
                    this.colors[colorpos++] = alpha;

                    this.colors[colorpos++] = alpha;
                    this.colors[colorpos++] = alpha;
                    this.colors[colorpos++] = alpha;

                    numConnected++;

                }
            }
        }

        this.linesMesh.geometry.setDrawRange(0, numConnected * 2);
        this.linesMesh.geometry.attributes.position.needsUpdate = true;
        this.linesMesh.geometry.attributes.color.needsUpdate = true;
        this.pointCloud.geometry.attributes.position.needsUpdate = true;

    }

    async initParticles(scene, element) {
        let boxSize = 0.2;
        let geometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize);
        let material = new THREE.MeshBasicMaterial({ transparent: false, color: element.color, opacity: 1, side: THREE.DoubleSide });
        //console.log(this.side)

        //document.addEventListener('mousemove', (event) => {  this.mousePos = {x:event.clientX/window.innerWidth, y:event.clientY/window.innerHeight};});
        //let group = new Group();
        let group = new THREE.Group();
        scene.add(group)
        this.mesh = group;
        //group.add(mesh);

        for (let p = 0; p < this.pitchSegments; p++) {
            let pitch = Math.PI * 2 * p / this.pitchSegments;
            for (let e = 0; e < this.elevationSegments; e++) {
                let elevation = Math.PI * ((e / this.elevationSegments) - 0.5)
                let particle = new THREE.Mesh(geometry, material);

                group.add(particle);

                let dest = new THREE.Vector3();
                dest.z = (Math.sin(pitch) * Math.cos(elevation)) * this.radius; //z pos in sphere
                dest.x = (Math.cos(pitch) * Math.cos(elevation)) * this.radius; //x pos in sphere
                dest.y = Math.sin(elevation) * this.radius; //y pos in sphere

                particle.position.x = this.posInBox(group.children.length % this.side);
                particle.position.y = this.posInBox(Math.floor(group.children.length / this.side) % this.side);
                particle.position.z = this.posInBox(Math.floor(group.children.length / Math.pow(this.side, 2)) % this.side);

                // dest.z = this.posInBox(group.children.length % this.side);
                // dest.x = this.posInBox(Math.floor(group.children.length / this.side) % this.side);
                // dest.y = this.posInBox(Math.floor(group.children.length / Math.pow(this.side, 2)) % this.side);

                // particle.position.x = (Math.sin(pitch) * Math.cos(elevation)) * this.radius; //z pos in sphere
                // particle.position.y = (Math.cos(pitch) * Math.cos(elevation)) * this.radius; //x pos in sphere
                // particle.position.z = Math.sin(elevation) * this.radius; //y pos in sphere
                //console.log(this.side, group.children.length, particle.position.x, particle.position.y, particle.position.z)
                particle.userData = { dests: [dest, particle.position.clone()], speed: new THREE.Vector3() };

                //console.log(particle)
            }
        }
    }

    async animateParticles() {
        this.phase += 0.002; // 0.002; // turning speed
        const l = this.mesh.children.length;
        for (let i = 0; i < l; i++) {
            let particle = this.mesh.children[i];
            let dest = particle.userData.dests[Math.floor(this.phase) % particle.userData.dests.length].clone();
            let diff = dest.sub(particle.position);
            particle.userData.speed.divideScalar(1.02); // Some drag on the speed
            particle.userData.speed.add(diff.divideScalar(400));// Modify speed of change by a fraction of the distance to the dest    
            particle.position.add(particle.userData.speed);
            particle.lookAt(dest);
        }

        this.mesh.rotation.y = this.phase * 3;
        // this.mesh.rotation.x = (this.mousePos.y-0.5) * Math.PI;
        // this.mesh.rotation.z = (this.mousePos.x-0.5) * Math.PI;
    }

    posInBox(place) {
        // console.log(place, this.side, this.radius)
        // return ((place/side) - 0.5) * radius * 1.2;  
        return ((place / this.side) - 0.5) * this.radius * 1.2;
    }

    initLightBublbs(scene, color, primairytimeline, effect) {
        const count = 200;
        let group = new THREE.Group();
        this.bulbs = [];


        for (let i = 0; i < count; i++) {

            let bulbMat = new THREE.MeshStandardMaterial({
                emissive: color,
                emissiveIntensity: 1,
                color: color
            });

            const bulbGeometry = new THREE.SphereGeometry(1, 16, 8); //0.02, 16, 8
            let bulbLight: Mesh = new THREE.Mesh(bulbGeometry, bulbMat);


            // get random position
            let xPos = THREE.MathUtils.randFloat(-500,500);
            let yPos = THREE.MathUtils.randFloat(-500,500);
            let zPos = THREE.MathUtils.randFloat(-500,500);
            bulbLight.position.set(xPos, yPos, zPos);

            let aniset: GSAPTimelineVars = {
                x: "random(-500,500)", 
                y: "random(-500,500)",
                z: "random(-500,500)",
                duration: "random(3,10)",
                ease: 'none',
                delay: 0,
                repeat: -1,
                repeatRefresh: true, // gets a new random x and y value on each repeat
                
            }

            primairytimeline.to(bulbLight.position, aniset, 0)

            effect.selection.toggle(bulbLight)
            //bulbLight.castShadow = true;
            group.add(bulbLight)
            this.bulbs.push(bulbLight);
        }

        scene.add(group);
        // console.log(this.bulbs, scene)
        return group
    }



    //   createPreTheme() {

    //     let boxSize = 0.2;
    //     let geometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize);
    //     let material = new THREE.MeshBasicMaterial({ transparent: false, color: this.element.color, opacity: 1, side: THREE.DoubleSide });
    //     //console.log(this.side)

    //     //document.addEventListener('mousemove', (event) => {  this.mousePos = {x:event.clientX/window.innerWidth, y:event.clientY/window.innerHeight};});
    //     //let group = new Group();
    //     let group = new THREE.Object3D();
    //     this.scene.add(group)
    //     //group.add(mesh);

    //     for (let p = 0; p < this.pitchSegments; p++) {
    //       let pitch = Math.PI * 2 * p / this.pitchSegments;
    //       for (let e = 0; e < this.elevationSegments; e++) {
    //         let elevation = Math.PI * ((e / this.elevationSegments) - 0.5)
    //         let particle = new THREE.Mesh(geometry, material);

    //         group.add(particle);

    //         let dest = new THREE.Vector3();
    //         dest.z = (Math.sin(pitch) * Math.cos(elevation)) * this.radius; //z pos in sphere
    //         dest.x = (Math.cos(pitch) * Math.cos(elevation)) * this.radius; //x pos in sphere
    //         dest.y = Math.sin(elevation) * this.radius; //y pos in sphere

    //         particle.position.x = this.posInBox(group.children.length % this.side);
    //         particle.position.y = this.posInBox(Math.floor(group.children.length / this.side) % this.side);
    //         particle.position.z = this.posInBox(Math.floor(group.children.length / Math.pow(this.side, 2)) % this.side);

    //         // dest.z = this.posInBox(group.children.length % this.side);
    //         // dest.x = this.posInBox(Math.floor(group.children.length / this.side) % this.side);
    //         // dest.y = this.posInBox(Math.floor(group.children.length / Math.pow(this.side, 2)) % this.side);

    //         // particle.position.x = (Math.sin(pitch) * Math.cos(elevation)) * this.radius; //z pos in sphere
    //         // particle.position.y = (Math.cos(pitch) * Math.cos(elevation)) * this.radius; //x pos in sphere
    //         // particle.position.z = Math.sin(elevation) * this.radius; //y pos in sphere
    //         //console.log(this.side, group.children.length, particle.position.x, particle.position.y, particle.position.z)
    //         particle.userData = { dests: [dest, particle.position.clone()], speed: new THREE.Vector3() };

    //         //console.log(particle)
    //       }
    //     }
    //     return group;
    //   }
}