
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { VideorequestobjectComponent } from './videorequestobject.component';
import { FormobjectComponent } from './formobject/formobject.component';


const routes: Routes = [

  { // node request hmtl5 to video
    path: 'videorequestobject', component: VideorequestobjectComponent
  },
  { // node request form filed
    path: 'formobject', component: FormobjectComponent
  },

  { 
    path: '', pathMatch: 'full', component: VideorequestobjectComponent 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routedComponents = [AppComponent];
