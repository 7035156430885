import { Injectable } from '@angular/core';
import { Draggable } from 'src/assets/js/all';
// import { VectorService } from './vector.service';
import gsap from 'gsap';
gsap.registerPlugin(Draggable);


@Injectable({
    providedIn: 'root'
})
export class DraggableService {

    public draggableObject: Draggable; // = new Draggable();

    constructor(
        //public vectorService: VectorService,
    ){

    }

    cancelDragSelect() {
        if (this.draggableObject) {
           // console.log('cancel')
            this.draggableObject.disable();
            // draggableObject.kill();
        }

    }








}