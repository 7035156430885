import { Injectable } from '@angular/core';
//import { ExtrudeGeometry } from 'three';
import { fromWorker } from 'observable-webworker';
import { of } from 'rxjs';
import CSG from './CSG';
//import { del, get, set } from 'idb-keyval';

@Injectable({
  providedIn: 'root'
})
export class ViewerworkerService {

  constructor() { }


  // async convertSVG(paths: any, setside: string, group: any, element: any, clippath: any): Promise<any> {
  //   let data = {
  //     paths: paths,
  //     setside: setside,
  //     group: group,
  //     element: element,
  //     clippath: clippath
  //   }

  //   if (typeof Worker !== 'undefined') {
  //     const worker = new Worker('./viewerworker.worker',
  //       { type: 'module' });

  //     worker.onmessage = ({ data }) => {
  //       console.log(`page got message: ${data}`);
  //       return data
  //     };

  //     worker.postMessage(data.group);
  //   }
  // }

  async CSGSubstract(geometryshapebase, geometryshapecormesh): Promise<CSG> {
    return new Promise(async (fullfill, reject) => {

      // convert to string
      const csgobjects = {geometryshapebase: geometryshapebase, geometryshapecormesh: geometryshapecormesh}
      let obj = JSON.parse(JSON.stringify(csgobjects));
      const input$ = of(obj);//of(geoid);

      // send to worker
      fromWorker<string, string>(() =>
        new Worker('./csgsubstractworker.worker', { type: 'module' }), input$)
        .subscribe(message => {
          let response = JSON.parse(message);
          let csgobj = response as CSG;
          fullfill(csgobj);
        });

    })
  };

  async CSGUnion(csggeo, geometryshape): Promise<CSG> {
    return new Promise(async (fullfill, reject) => {

      const csgobjects = {csggeo: csggeo, geometryshape: geometryshape}
      let obj = JSON.parse(JSON.stringify(csgobjects));
      const input$ = of(obj);//of(geoid);

      // create worker 
      fromWorker<string, string>(() =>
        new Worker('./csgunionworker.worker', { type: 'module' }), input$)
        .subscribe(message => {
          let response = JSON.parse(message);
          let csgobj = response as CSG;
          fullfill(csgobj);
        });

    })
  };

  async CSGcreateFromGeometry(shape, exdepth): Promise<CSG> {
    return new Promise(async (fullfill, reject) => {

      const csgobjects = {exdepth: exdepth, shape: shape}
      let obj = JSON.parse(JSON.stringify(csgobjects));
      const input$ = of(obj);//of(geoid);

      // create worker 
      fromWorker<string, string>(() =>
        new Worker('./csgcreatefromgeometry.worker', { type: 'module' }), input$)//.toPromise();
        .subscribe(message => {
          let response = JSON.parse(message);
          let csgobj = response as CSG;
          fullfill(csgobj);
        });

    })
  }

}
