import { Injectable } from '@angular/core';
import { Application, Container, Sprite, Assets, Rectangle, WRAP_MODES, Texture, BaseTexture, Renderer } from 'pixi.js';
import { GlitchFilter } from '@pixi/filter-glitch';
import { ShockwaveFilter } from '@pixi/filter-shockwave';
import { CrossHatchFilter } from '@pixi/filter-cross-hatch';
import { OldFilmFilter } from '@pixi/filter-old-film';
import { DisplacementFilter } from '@pixi/filter-displacement';
import { EmbeddedVideo } from '../videocreator.model';


@Injectable({
  providedIn: 'root'
})
export class EmbeddedpixiService {
  public app: Application;

  constructor() { }

  async setPixi(elm: EmbeddedVideo, primairytimeline) {
    let w = parseInt(elm.style.width);
    let h = parseInt(elm.style.height);

    let x = document.getElementById(elm.id + "canvaseffectlayer") as HTMLCanvasElement;
    // let x = document.createElement("canvas");
    // x.setAttribute("id", elm.id + "canvaseffectlayer");
    // x.width = w;
    // x.height = h;

    //uppercontainer.appendChild(x);
    let background = await this.setBGPixi(primairytimeline, elm.id, elm.start_time) as Sprite;
    background.width = w;
    background.height = h;

    // check if app already exists?
    //if (this.app) {
      this.app = new Application({
        width: w, 
        height: h,
        view: x, 
        backgroundAlpha: 0,
       // transparent: false
      });
   // }


    if (elm.effect === 'shockwave') {
      // new Loader()
      //   .load((loader, resources) => {
          this.shockwaveLoad(primairytimeline, background, w, h);
      //  });
    }

    if (elm.effect === 'glitch') {
      // new Loader()
      //   .load((loader, resources) => {
          this.glitchLoad(primairytimeline, background);
        //});
    }

    if (elm.effect === 'matrix') {
      // new Loader()
      //   .load((loader, resources) => {
          this.matrixLoad(primairytimeline, background, w, h);
      //  });
    }


    if (elm.effect === 'oldfilm') {
      // new Loader()
      //   .load((loader, resources) => {
          this.oldFilmLoad(primairytimeline, background, w, h);
     //   });
    }

    if (elm.effect === 'pond') {
      //let resource = new Loader().load("assets/displacementmap.png")
        // .add("displacementMap", "assets/displacementmap.png")
        // .load((loader, resources) => {
           this.pondLoad(primairytimeline, background, w, h);
        // });
    }

    if (elm.effect === 'oldfilm') {
      // new Loader()
      //   .load((loader, resources) => {
          this.oldFilmLoad(primairytimeline, background, w, h);
      //  });
    }
  }



  async glitchLoad(primairytimeline, background) {

    let container = new Container();
    let glitchFilter = new GlitchFilter();

    // glitchFilter.blue.y = 0;
    // glitchFilter.blue.x = 0;
    // glitchFilter.red.y = 0;
    // glitchFilter.red.x = 0;
    // glitchFilter.green.x = 0;
    // glitchFilter.green = y: 0;

    glitchFilter.offset = 0;
    glitchFilter.seed = 0;

    container.filters = [glitchFilter];
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(glitchFilter, { seed: Math.random() }, 0);
    primairytimeline.to(glitchFilter, {
      ease: 'linear.easeNone',
      repeat: -1,
      duration: 0.1,
      red: { x: 5, y: 6 },
    }, 0);
    primairytimeline.to(glitchFilter, {
      ease: 'linear.easeNone',
      repeat: -1,
      duration: 0.5,
      repeatDelay: 1,
      offset: 100,
      fillMode: 2,
      slice: 10
    }, 0);

    // const video = document.getElementById('videobgcontainer') as HTMLVideoElement;
    // video.parentNode.removeChild(video);
  }

  async pondLoad(primairytimeline, background, w, h) {
    //this.app. .loader.add("displacementMap", "assets/displacementmap.png");
    let resources = await Assets.load("assets/displacementmap.png") //.then((data) => this.onSpineLoaded(data))

    let container = new Container();
  //  const loader = new PIXI.Loader(); // you can also create your own if you want

    let displacementSprite = new Sprite(resources.displacementMap.texture);
    let displacementFilter = new DisplacementFilter(displacementSprite);

    container.filterArea = new Rectangle(-10, -10, w + 10, h + 10);
    container.filters = [displacementFilter];
    displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;

    container.addChild(background);
    container.addChild(displacementSprite);
    this.app.stage.addChild(container);

    primairytimeline.to(displacementSprite, {
      ease: 'none',
      repeat: -1,
      duration: 2,
      yoyo: true,
      x: w,
      y: h
    }, 0);
  }

  async shockwaveLoad(primairytimeline, background, w, h,) {

    let container = new Container();
    let shockwaveFilter = new ShockwaveFilter();
    shockwaveFilter.center = [w / 2, h / 2];
    shockwaveFilter.brightness = 1.5; 
    shockwaveFilter.radius= 0.0; 
    shockwaveFilter.speed= (w / 3);
    container.filters = [shockwaveFilter];

    background.width = w + 100;
    background.height = h + 100;
    //container.position.set(-10);
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(shockwaveFilter, {
      ease: 'linear.easeNone',
      time: 2,
      repeat: -1,
      duration: 3,
    }, 0);
  }

  async matrixLoad(primairytimeline, background, w, h,) {

    let container = new Container();
    let crossHatchFilter = new CrossHatchFilter();
    container.filters = [crossHatchFilter];


    background.width = w;
    background.height = h;
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(crossHatchFilter, {
      ease: 'linear.easeNone',
      repeat: -1,
      duration: 3,
    }, 0);
  }

  async oldFilmLoad(primairytimeline, background, w, h,) {

    let container = new Container();
    let oldFilmFilter = new OldFilmFilter();
    // oldFilmFilter.sepia = 0.3,
    //   oldFilmFilter.noise = 0.3,
    //   oldFilmFilter.noiseSize = 2,
    //   oldFilmFilter.scratch = 0.5,
    //   oldFilmFilter.scratchDensity = 0.3,
    //   oldFilmFilter.scratchWidth = 10,
    //   oldFilmFilter.vignetting = 0.3,
    //   oldFilmFilter.vignettingAlpha = 0.3,
    //   oldFilmFilter.vignettingBlur = 0.3,
    //   oldFilmFilter.seed = 0.3;
    container.filters = [oldFilmFilter];


    background.width = w;
    background.height = h;
    container.addChild(background);
    this.app.stage.addChild(container);

    primairytimeline.to(oldFilmFilter, {
      vignetting: 0.3,
      vignettingAlpha: 0.3,
      vignettingBlur: 0.3,
      duration: 1,
    }, 0);
    primairytimeline.to(oldFilmFilter, {
      ease: 'none',
      sepia: 0.3,
      seed: Math.random(),
      noise: 0.3,
      noiseSize: 1,
      scratch: 0.5,
      scratchDensity: 0.3,
      scratchWidth: 3,
      //vignetting: 0.3,
      //vignettingAlpha: 0.3,
      //vignettingBlur: 0.3,
      repeat: -1,
      duration: 3,
    }, 0);
  }


  async setBGPixi(primairytimeline, id, starttime) {
    return new Promise((resolve, reject) => {
      const video = document.getElementById(id).getElementsByTagName('video')[0];
      let videoRen = Texture.from(video);
      let background = new Sprite(videoRen) as any;
      background.preload = 'auto';
      background.autoplay = false;
      let rscVid = background.texture.baseTexture.resource as any;
      rscVid.autoPlay = false;
      const videoControler = rscVid.source;
      videoControler.pause();
      primairytimeline.call(this.playPixiVideo, [videoControler], starttime);
      resolve(background);
    });
  }

  playPixiVideo(videoControler) {
    videoControler.play();
  }

}
