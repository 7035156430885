import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { gsap } from '../assets/js/all';
import { CustomBounce, CustomEase, PixiPlugin, Physics2DPlugin, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper, Draggable } from '../assets/js/all';
gsap.registerPlugin(Physics2DPlugin, PixiPlugin, CustomEase, CustomBounce, Draggable, InertiaPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin, MorphSVGPlugin, MotionPathPlugin, MotionPathHelper);
const plugins = [PixiPlugin, Draggable, CustomEase, CustomBounce, InertiaPlugin, DrawSVGPlugin, MorphSVGPlugin, ScrambleTextPlugin, SplitText, Physics2DPlugin, MotionPathPlugin, MotionPathHelper]; //needed for GSAP
import { Router, ActivatedRoute, Route } from '@angular/router';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
// import { Application, Container, Sprite, Loader, Rectangle, WRAP_MODES, Texture, BaseTexture, Renderer } from 'pixi.js';
import { ShockwaveFilter } from '@pixi/filter-shockwave';
import { GlitchFilter } from '@pixi/filter-glitch';
import { CrossHatchFilter } from '@pixi/filter-cross-hatch';
import { OldFilmFilter } from '@pixi/filter-old-film';
import { SVG } from '@svgdotjs/svg.js';
import { CountUpOptions, CountUp } from 'countup.js';
import { Subject } from 'rxjs';
import { VectorService } from './supportfunctions/vector.service';
import { BackgroundeffectsService } from './supportfunctions/backgroundeffects.service';
import { AudioService } from './supportfunctions/audio.service';
import { CodegeneratorService } from './supportfunctions/codegenerator.service';
import { EasetypesService } from './supportfunctions/easetypes.service';
import { AnimationsService } from './supportfunctions/animations.service';
import { vectorelement } from './videocreator.model';
import { EmbeddedpixiService } from './supportfunctions/embeddedpixi.service';

@Component({
  selector: 'app-videorequestobject',
  templateUrl: './videorequestobject.component.html',
  styleUrls: ['./videorequestobject.component.scss']
})
export class VideorequestobjectComponent implements OnInit {

  eventsSubject: Subject<void> = new Subject<void>();
  bufferValue: number;
  overflow: string;
  busy: boolean;

  emitEventToChild(formId) {
    this.eventsSubject.next(formId);
  }

  public videoPlayer: HTMLVideoElement;
  @ViewChild('videoPlayer')
  set mainVideoEl(el: ElementRef) {
    if (el !== undefined) {
      this.videoPlayer = el.nativeElement;
    }
  }

  public position = '';
  public left = '0px';
  public top = '0px';
  public videoOn = true;
  public muted = true;


  public sub: any;
  public videoobject: any;
  public canvas: any;
  public animationarray: any; //array with style and position settings;
  public animationelements = []; //arrat with the actual greensock animations
  public t: any;
  public counter = 1000;
  public currenttime = 0;
  public play = false;
  public primairytimeline: GSAPTimeline = gsap.timeline({ paused: true, reversed: true }); //gsap timeline control
  public changenow = true;
  public changevideo = false;
  public listviewxsshow = false;
  public showprogressbar = false;
  public shiftX = 0;
  public shiftY = 0;
  public moveitem = false;
  public showemoji = false;
  public newz = 1;
  public inBounds = true;
  public edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  public editpath = false;
  public activeMediaQuery: any;
  public selectedelement: any;
  public elementname: any;
 // public count = 0;
  //public repeat = false;
  public setreplay = false;
  public remote: any;
  public zoomfactor = 1;
  public videourl: string;
  public canvasopcity = 0;
  playing: boolean = false;

  constructor(
    private embeddedpixi: EmbeddedpixiService,
    public codegeneratorService: CodegeneratorService,
    public vectorService: VectorService,
    private backgroundeffectsService: BackgroundeffectsService,
    private audioService: AudioService,
    private easetypesService: EasetypesService,
    private animationsService: AnimationsService,
    public route: ActivatedRoute,
    public http: HttpClient,
    public router: Router,
  ) {
    console.log('loaded 0.9.19')
    let id = this.route.snapshot.queryParamMap.get("id");
    let canvasjs = this.route.snapshot.queryParamMap.get('canvas');
    let repeat = this.route.snapshot.queryParamMap.get('repeat') as string;
    this.setreplay = (repeat == 'true');
    let remote = this.route.snapshot.queryParamMap.get('remote') as string;
    if (remote === 'true') { this.remote = true } else {
      this.remote = false;
    }
    this.canvas = JSON.parse(canvasjs);
    let count = this.route.snapshot.queryParamMap.get('counter') as string;
    console.log('remote: ', this.remote) //  check if is puppeteer or actuall session!!
    console.log(this.canvas);
    // if true get videofile details from query and api
    if (this.remote) {
      this.busy = true;
      this.getRemoteJSON(id).subscribe(async response => {
        this.animationarray = response.template; //JSON.parse(animationarrayjs)
        let three = await this.checkForThreed();
        //console.log(three)
        this.detectchange();
        this.canvas = response.canvas[0];
        this.counter = response.counter;
        //  console.log(this.counter)

        //if remote true show waiting animation


        if (!this.canvas || !this.animationarray) {
          console.error('missing canvas or animation template')
        } else {
          if (this.canvas.videourl) {
            // test purpose only
            console.log(this.canvas.videourl)
            //this.canvas.videourl = this.canvas.videourl.replace('https://api.enydea.com','http://localhost:3000')
            await this.onchangevideo();
            this.changevideo = true;
          } else {
            this.changevideo = true;
            
          }
          this.setreplay = false
          this.busy = false;
          this.playFunc();
        }
      });
    } else {
      // serverside rendering! 
      // get JSON videofile from dlcr server serverside rendering! 
      this.getJSON(id).subscribe(async response => {
        //console.log(JSON.stringify(response))
        this.animationarray = response; //JSON.parse(animationarrayjs)
        //console.log(this.canvas)
        this.detectchange();
        this.canvas.audio = ''; // only remote as audio is seperate process
        this.counter = parseInt(count);
        console.log(this.counter)
        if (!this.canvas || !this.animationarray) {
          console.error('missing canvas or animation template')
        } 
        
        await this.runLastCheck()
        await new Promise(resolve => setTimeout(resolve, 200)); // wait for dom to finsih
        this.playFunc();
      });
    }
  }

  async runLastCheck(): Promise<void> {
    console.log('last check')
    //  await this.checkForThreed();
    if (this.canvas.overflow) {
      this.overflow = 'visible'
    } else { this.overflow = 'hidden' }

    if (this.canvas.videourl) {
      await this.onchangevideo();
    }
    this.setreplay = true; // prevent play button from showing also not necessary anymore
    //this.changevideo = true; // not necessary anymore
    await this.checkForThreed();
    return
  }

  async checkForThreed(): Promise<Boolean> {
    let threed = false;
    for (let i = 0; i < this.animationarray.length; i++) {
      if (this.animationarray[i].type === 'threed' && this.animationarray[i].src) {
        await this.getThreedFile(this.animationarray[i], this.animationarray[i].src)
        threed = true;
      }
    }
    return threed
  }

  async getThreedFile(element, url): Promise<boolean> {
    // console.log(url.indexOf('https://api.enydea.com'))
    // if (url.indexOf('https://api.enydea.com') !== -1){
    //   url = url.replace('https://api.enydea.com', 'http://localhost');
    // }
    let fileready = false;
    let event = await this.downloadFile(url).toPromise();
    if (event.type === HttpEventType.Response) {
      console.log("donwload completed");
      let urlCreator = window.URL;
      element.blobsrc = urlCreator.createObjectURL(event.body);
      fileready = true;
      return fileready
    }


  }

  downloadFile(url): Observable<HttpEvent<any>> {
    return this.http.get(url, {
      responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders()
    });
  }

  public getRemoteJSON(id): Observable<any> {
    let file = 'https://app.enydea.com/api/Files/' + id; //http://localhost:3000/api/Files/5dfcf130f79b420078d5fbc9
    return this.http.get(file);
  }

  public getJSON(id): Observable<any> {
    let file = '../assets/json/' + id + '/' + id + '.json';
    return this.http.get(file);
  }

  public setZoom() {

    let y = screen.height;
    let x = screen.width;


    x = window.innerWidth;
    y = window.innerHeight;
    // console.log(x, y)

    if (y === 0 && x === 0) {
      x = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      y = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      // console.log(x, y)
    }


    const width = parseInt(this.canvas.width);
    const height = parseInt(this.canvas.height);

    if (x < width || y < height) {
      let zoomx = x / width;
      let zoomy = y / height;
      //this.zoomfactor = zoomx;
      if (zoomx < zoomy) {
        this.zoomfactor = zoomx;
      } else {
        this.zoomfactor = zoomy;
      }
    }

    if (this.zoomfactor !== 1) {
      let scale = this.zoomfactor - 1;
      let w = width / 2;
      let h = height / 2;
      this.left = w * scale + 'px';
      this.top = h * scale + 'px';
      this.position = 'absolute';
    }
    if (this.zoomfactor === 1) {
      this.left = '0px';
      this.top = '0px';
    }

    //console.log(x, y, width, height, this.zoomfactor, this.canvas);
  }

  ngOnInit() {
    //console.log('dom initialized');

  }

  createCounter(elm) {
    let start_time = elm.countertype[0].start_time;
    for (let i = 0; i < elm.countertype.length; i++) {
      let countertype = elm.countertype[i];
      let doc = document.getElementById(elm.id + 'svgcircle' + i);
      //console.log(doc)
      this.setDrawAni(doc, countertype);
      if (countertype.start_time < start_time) {
        start_time = countertype.start_time
      }
    }

    const countUp = new CountUp(elm.id + 'num', elm.maxcount, elm);
    this.primairytimeline.call(this.startCounter, [countUp, elm.id, elm.maxcount], start_time);
  }

  async startCounter(countUp: CountUp, id, count) {
    countUp.start();
  }


  async detectchange(): Promise<void> {
    return new Promise(async (fullfill) => {
    console.log(this.animationarray)

    this.stopFunc();
    //  this.systembusy = true;
    // if (this.selectedelement && this.selectedelement.type === 'vector') {
    //   // this.vectorService.saveSVG(this.selectedelement);
    //   this.vectorService.saveSVG(this.selectedelement);
    //   this.vectorService.removeVectorPathMultiSelection(this.selectedelement);
    // }
    // if (this.editpath === true) {
    //   this.saveNewMotionPath();
    // }
    //  if (this.whiteboard) { this.deletewhiteboard() }

    // reset timeline
    this.primairytimeline = gsap.timeline({ paused: true, reversed: true });

    // force dom update
    this.changenow = false;
    await new Promise(resolve => setTimeout(resolve, 10));
    this.changenow = true;
    // wait for dom update to finish otherwise it will create the effects on the old dom
    // detectchange angular also should work but did not get it to work properly 
    await new Promise(resolve => setTimeout(resolve, 100));

    if (this.canvas.weather !== '') { await this.addWeatherEffect() };

    // Create specific animations and set positions
    for (let i = 0; i < this.animationarray.length; i++) {
      const elm = this.animationarray[i];
      await this.setPosition(elm);

      if (elm.type === 'chart') {
        await this.setChartType(elm)
      }


      if (elm.type === 'embeddedvideo') {
        this.setEmbeddedVideo(elm);
      }

      if (elm.type === 'vector') { //vector animation
        for (let i2 = 0; i2 < elm.vectoranimation.length; i2++) {
          const vecani = elm.vectoranimation[i2];
          if (vecani.svganimationtype === 'draw') { this.vectorService.drawVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'liquid') { this.vectorService.createWaveVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'color') { this.vectorService.createColorVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'changepath') { this.vectorService.createChangePathVector(elm, vecani, this.primairytimeline) }
          if (vecani.svganimationtype === 'morph') {
            if (elm.vectors.length > 1) { await this.createMorph(elm, vecani) }
          }
        }
      }

      if (elm.type === 'counter') {
        await this.createCounter(elm,);
      }

      if (elm.type === 'text') {
        for (let i3 = 0; i3 < elm.splittextanimation.length; i3++) {
          const textani = elm.splittextanimation[i3];
          if (textani.textanimationtype) { await this.createSplitText(elm, textani) }
        }
      }

      await this.addEffect(elm); //add generic animations
    }
    //  this.systembusy = false;

    //  this.setGrid();
    fullfill();
  });
  }


  setChartData(elm) {
    elm.productiondata = [];
    for (let k = 0; k < elm.data.length; k++) {
      let productiondataset = { data: [], label: elm.data[k].label };
      elm.data[k].data.forEach(element => {
        productiondataset.data.push(element);
      });
      elm.productiondata.push(productiondataset);
    }
  }

  setPosition(idel) {
    //console.log('set pos', idel)
    //if animation is move --> path determines position
    let elm = document.getElementById(idel.id);
    gsap.set(elm, { x: idel.posx, y: idel.posy, rotation: idel.rotation });
  }

  async createMorph(element, animation) {
    // create vector animation foreach path vector 1 to 2, 2 to 3 etc..
    // add appear and dissapear effect for if the paths are uneven
    let vectors: vectorelement[];
    vectors = element.vectors;
    let ease = this.easetypesService.selectEaseType(animation.easetype);

    // await this.combineSVGs(element); // takes to long to rebuild

    for (let i1 = 0; i1 < vectors.length - 1; i1++) {
      let fromvector = vectors[i1];
      let tovector = vectors[i1 + 1];
      let fintime = animation.start_time + animation.duration + (animation.duration * i1);
      let fintimehalf = animation.duration / 0.5;
      let starttime = animation.start_time + (animation.duration * i1) + (1 * i1);
      let repeat = animation.repeat;
      let yoyo = animation.yoyo;

      //console.log(yoyo);
      // if vector 1 has less paths then vector 2
      if (vectors[i1].pathids.length < vectors[i1 + 1].pathids.length) {
        for (let ix = 0; ix < vectors[i1 + 1].pathids.length; ix++) {
          // copy random vector paths to connect to empty paths
          if (ix >= vectors[i1].pathids.length) {

            let topathid = vectors[i1 + 1].pathids[ix];
            let toel = document.getElementById(topathid);
            let vectornewpathset = document.getElementById('0elvect-res' + ix) as unknown;
            let vectornewpath = vectornewpathset as SVGPathElement;
            //console.log(vectornewpath);
            if (vectornewpath === null) {
              const sindex = Math.floor(Math.random() * fromvector.pathids.length); //connect to random paths;
              const frompathid = fromvector.pathids[sindex];
              const fromel = document.getElementById(frompathid);
              const svgnew = fromel.parentElement;// vectornew.getElementsByTagName('svg');
              const newid = '0elvect-res' + ix;
              const checkifexit = document.getElementById(newid) as unknown;
              if (checkifexit == null) {
                const newElement = fromel.cloneNode(true) as HTMLElement;
                newElement.setAttribute('id', newid);
                svgnew.insertAdjacentElement('afterbegin', newElement)
                //svgnew.appendChild(newElement);
                let vectornewpathset = document.getElementById('0elvect-res' + ix) as unknown;
                vectornewpath = vectornewpathset as SVGPathElement;
              } else {
                vectornewpath = checkifexit as SVGPathElement;
              }
            }
            //this.primairytimeline.set()
            if (repeat !== -1) {
              this.primairytimeline.set(vectornewpath, { morphSVG: { shape: vectornewpath }, autoAlpha: 1 }, 0); //reset to original
              this.primairytimeline.fromTo(toel, { autoAlpha: 0 }, { duration: fintimehalf, autoAlpha: 1, repeat: repeat, yoyo: yoyo }, fintime - 1);
              this.primairytimeline.to(vectornewpath, { duration: 1, autoAlpha: 0, repeat: repeat, yoyo: yoyo }, fintime);
            }
            if (repeat === -1) {
              this.primairytimeline.set(toel, { autoAlpha: 0 }, 0); //reset to original
              this.primairytimeline.set(vectornewpath, { autoAlpha: 1 }, 0); //reset to original
            }

            let vars: GSAPTimelineVars = {
              duration: animation.duration, morphSVG: {
                shape: toel,
                //type: "rotational",
                //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
              }, ease: ease, repeat: repeat, repeatDelay: animation.delay, yoyo: yoyo
            }

            this.primairytimeline.to(vectornewpath, vars, starttime);

          }
        }
      }

      for (let i2 = 0; i2 < fromvector.pathids.length; i2++) {
        // vector 1 is eqeal or smaller then vector 2
        if (i2 < tovector.pathids.length) {
          let frompathid = fromvector.pathids[i2];
          let topathid = tovector.pathids[i2];
          let fromelset = document.getElementById(frompathid) as unknown;
          let fromel = fromelset as SVGPathElement;
          let toel = document.getElementById(topathid);


          // always reset morph element
          this.primairytimeline.set(fromel, {
            morphSVG: {
              shape: fromel,
            }
          }, 0);
          if (repeat !== -1) {
            this.primairytimeline.set(fromel, { morphSVG: { shape: fromel }, autoAlpha: 1 }, 0); //reset to original
            this.primairytimeline.fromTo(toel, { autoAlpha: 0 }, { duration: fintimehalf, autoAlpha: 1, repeat: repeat, yoyo: yoyo }, fintime - 1);
            this.primairytimeline.to(fromel, { duration: 1, autoAlpha: 0, repeat: repeat, yoyo: yoyo }, fintime);

            let vars: GSAPTimelineVars = {
              duration: animation.duration,
              morphSVG: {
                shape: toel,
                //type: "rotational",
                //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
              }, ease: ease, repeat: repeat,
            }

            this.primairytimeline.to(fromel, vars, starttime);
          }
          if (repeat === -1) {
            let ease2 = ease.replace('in', 'out')
            this.primairytimeline.set(toel, { autoAlpha: 0 }, 0); //reset to original
            this.primairytimeline.set(fromel, { autoAlpha: 1 }, 0); //reset to original

            let vars1: GSAPTimelineVars = {
              duration: animation.duration / 2,
              morphSVG: {
                shape: toel,
              }, ease: ease, repeat: repeat
            }

            let vars2: GSAPTimelineVars = {
              delay: animation.duration / 2,
              repeatDelay: animation.duration / 2,
              duration: animation.duration / 2,
              morphSVG: {
                shape: fromel,
              },
              ease: ease2,
              repeat: repeat,
            }

            this.primairytimeline.to(fromel, vars1, starttime);
            this.primairytimeline.to(fromel, vars2, starttime);
          }

          let varsmisc: GSAPTimelineVars = {
            duration: animation.duration,
            morphSVG: {
              shape: toel,
              //type: "rotational",
              //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
            }, ease: ease, repeat: repeat
          }
          this.primairytimeline.to(fromel, varsmisc, starttime);


        } else { // (i2 > tovector.pathids.length)
          // vector 1 is larger then vector 2
          let frompathid = fromvector.pathids[i2];
          let sindex = Math.floor(Math.random() * tovector.pathids.length); //connect to random paths;
          let topathid = tovector.pathids[sindex];
          let fromel = document.getElementById(frompathid) as unknown;
          let fromel1 = fromel as SVGPathElement;
          let toel = document.getElementById(topathid);

          if (repeat !== -1) {
            this.primairytimeline.set(fromel1, { morphSVG: { shape: fromel1 }, autoAlpha: 1 }, 0); //reset to original
            this.primairytimeline.fromTo(toel, { autoAlpha: 0 }, { duration: fintimehalf, autoAlpha: 1, repeat: repeat, yoyo: yoyo }, fintime - 1);
            this.primairytimeline.to(fromel1, { duration: 1, autoAlpha: 0, repeat: repeat, yoyo: yoyo }, fintime);
          }
          if (repeat === -1) {
            this.primairytimeline.set(toel, { autoAlpha: 0 }, 0); //reset to original
            this.primairytimeline.set(fromel1, { autoAlpha: 1 }, 0); //reset to original
          }

          let vars: GSAPTimelineVars = {
            duration: animation.duration, morphSVG: {
              shape: toel,
              //type: "rotational",
              //origin: "50% 50%" //or "20% 60%,35% 90%" if there are different values for the start and end shapes.
            }, ease: ease, repeat: repeat, yoyo: yoyo
          }
          this.primairytimeline.to(fromel1, vars, starttime);

        }
      }
    }
  }

  async setEmbeddedVideo(elm) {

    let videodoc = document.getElementById(elm.id);
    let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
    //console.log(video)

    if (elm.start_time === undefined) { elm.start_time = 0 }
    this.primairytimeline.call(this.playEmbeddedVideo, [elm.id], elm.start_time);

    elm.duration = this.counter;
    if (video && video.duration) {
      elm.duration = video.duration;
      this.primairytimeline.call(this.stopEmbeddedVideo, [elm.id], elm.duration);
    }

    if (elm.effect) {
      this.embeddedpixi.setPixi(elm, this.primairytimeline);
    }

  }

  stopAllEmbeddedVideo() {
    this.animationarray.forEach((element, i) => {
      if (element.type === 'embeddedvideo') {
        this.stopEmbeddedVideo(element.id);

      }
    });
  }

  stopEmbeddedVideo(id, reset?) {
    let videodoc = document.getElementById(id);
    if (!videodoc) { return }
    let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
    if (videodoc === undefined) { console.error('missing video html element') }
    video.pause();
  }

  playEmbeddedVideo(id) {
    let videodoc = document.getElementById(id);
    let video = videodoc.getElementsByTagName('video')[0] as HTMLVideoElement;
    if (videodoc === undefined) { console.error('missing video html element') }
    video.muted = true;
    video.play();
  }


  async setChartType(elm) {
    elm.productiondata = [];
    elm.data = [];
    for (let k = 0; k < elm.originaldata.length; k++) {
      //console.log(elm.originaldata[k])
      let productiondataset = { data: [], label: elm.originaldata[k].label };
      let newdataset = { data: [], label: elm.originaldata[k].label };
      elm.originaldata[k].data.forEach(element => {
        productiondataset.data.push(0);
        newdataset.data.push(element);
      });
      elm.productiondata.push(productiondataset);
      elm.data.push(newdataset);
    }
    if (elm.start_time === undefined) { elm.start_time = 0 }
    this.primairytimeline.call(this.setChartData, [elm], elm.start_time);
    //console.log(elm)

  }

  createSplitText(elm: any, textani: any) {
    let splittextwhere = textani.textanimationtype;
    let id = document.getElementById(elm.id);
    let splitText = new SplitText(id, { type: textani.textanimationtype })
    let toset = {
      y: 100,
      autoAlpha: 0
    }
    let char = splitText.chars;
    let word = splitText.words;
    let line = splitText.lines;
    let setto;
    let lengtharr;
    if (textani.textanimationtype === 'chars') { setto = char; lengtharr = char.length }
    if (textani.textanimationtype === 'words') { setto = word; lengtharr = word.length }
    if (textani.textanimationtype === 'lines') { setto = line; lengtharr = line.length }
    let dura = textani.duration / lengtharr;
    // stagger durationis for each stag (word line character etc.. )

    let ease = this.selectEaseType(textani.easetype);
    if (textani.fromto === 'from') {
      this.primairytimeline.from(setto, { duration: textani.duration, x: textani.y, y: textani.x, rotationX: textani.rotationX, rotationY: textani.rotationY, autoAlpha: 0, ease: ease, stagger: 0.1, delay: textani.start_time }, 0)
    }
    if (textani.fromto === 'to') {
      this.primairytimeline.to(setto, { duration: textani.duration, x: textani.y, y: textani.x, rotationX: textani.rotationX, rotationY: textani.rotationY, autoAlpha: 0, ease: ease, stagger: 0.1, delay: textani.start_time }, 0)
    }
  }

  async setMorphAni(from, to, animation) {
    // console.log(from, to, animation);
    let ease = this.selectEaseType(animation.easetype);
    let fintime = animation.start_time + animation.duration;
    let fromset = {}
    let toset = {
      morphSVG: {
        shape: to,
        type: "rotational",
        origin: "20% 60%"
      },
      ease: ease
    };

    // this.primairytimeline.fromTo(from, animation.duration, toset, animation.start_time);
    this.primairytimeline.to(from, { duration: animation.duration, morphSVG: to, ease: ease }, animation.start_time);
    this.primairytimeline.to(to, { duration: 1, opacity: 1, }, fintime);
    this.primairytimeline.to(from, { duration: 1, opacity: 0 }, fintime);
    // this.primairytimeline.to(to, animation.duration, {opacity}, animation.start_time);
    return
  }

  playSound(id, src, loop) {
    let audio = document.getElementById(id) as HTMLAudioElement;
    //console.log(id, audio);
    if (!this.muted) {
      audio.play();
      audio.loop = loop;
    }

  }


  stopSound(id, src) {
    let audio = document.getElementById(id) as HTMLAudioElement;
    //console.log(audio);
    if (!this.muted) {
      audio.currentTime = 0;
      audio.pause();
    }
  }

  pauseSound(id, src) {
    let audio = document.getElementById(id) as HTMLAudioElement;
    //console.log(audio);
    if (!this.muted) {
      audio.pause();
    }
  }



  async addEffect(element) {
    let id = document.getElementById(element.id);

    for (let i = 0; i < element.animation.length; i++) {
      let animationsection = element.animation[i];
      this.animationsService.addAnimation(id, animationsection, element, i, this.canvas, this.primairytimeline);
    }

    // add placeholder to set timeline
    if (element.animation.length === 0) {


      this.primairytimeline.from(id, { duration: this.counter }, 0);
    }

  }

  addNewEffect(element): void {
    let rotationcycle = '0';
    if (this.selectedelement.rotation !== 0) {
      rotationcycle = this.selectedelement.rotation;
    }
    let newanimation = {
      start_time: 0, //delayt
      end_time: 10,
      anim_type: 'scale',
      duration: 3,
      ease: '',
      posx: this.selectedelement.posx,
      posy: this.selectedelement.posy,
      rotationcycle: rotationcycle,
      travellocX: -50,
      travellocY: -50,
      scalesize: 0.8,
      skewY: 50,
      skewX: 50,
      easetype: 'elastic',
      fromto: 'to',
      transformOriginX: '50%',
      transformOriginY: '50%',
      repeat: 0,
      yoyo: false,
      audioeffectsrc: '',
      rotationkeeppos: true
    }
    this.selectedelement.animation.push(newanimation);
    // this.detectchange();
    //console.log(this.selectedelement);
  }



  selectEaseType(type) {
    let ease;
    switch (type) {
      case 'bounce':
        ease = 'bounce.out';
        break;
      case 'bouncein':
        ease = 'bounce.in';
        break;
      case 'elastic':
        ease = 'elastic.out(1, 0.3)'
        break;
      case 'elasticin':
        ease = 'elastic.in(1, 0.3)'
        break;
      case 'circle':
        ease = 'circ.out'
        break;
      case 'circlein':
        ease = 'circ.in'
        break;
      case 'sine':
        ease = 'sine.out'
        break;
      case 'sinein':
        ease = 'sine.in'
        break;
      case 'over':
        ease = 'back.out(1.7)'
        break;
      case 'power1':
        ease = 'power1.out'
        break;
      case 'power1in':
        ease = 'power1.in'
        break;
      case 'power2':
        ease = 'power2.out'
        break;
      case 'power3':
        ease = 'power3.out'
        break;
      case 'easy':
        ease = 'power0.out'
        break;
      case 'slowmotion':
        ease = 'slow(0.7, 0.7, false)'
      case 'rough':
        ease = 'rough'
      case 'none':
        ease = 'none'
      default:
        ease = 'none';
    }
    return ease
  }



  playPixiVideo(videoControler) {
    videoControler.play();
  }


  navigateOut(element) {
    //console.log(element);
    if (element.onclickurl) {
      if (element.onclickurl.indexOf('http') == -1) {
        element.onclickurl = 'https://' + element.onclickurl
      }
      // var iFrameDetection = (window === window.parent) ? false : true;
      // console.log(iFrameDetection)
      if (window.parent) {
        window.parent.location.href = element.onclickurl;

      } else {
        window.location.href = element.onclickurl;
      }
    }
  }


  hideImageVideo() {
    // hide image or video if neccessary
    let img = document.getElementById("imagebgcontainer");
    if (img) {
      img.style.opacity = "0";
    }

    let vid = document.getElementById("videobgcontainer");
    if (vid) {
      vid.style.opacity = "0";
    }

  }

  isEven(n) {
    return n % 2 == 0;
  }

  async addWeatherEffect() {
    await this.backgroundeffectsService.addWeatherEffect(this.canvas, this.primairytimeline, this.videourl, this.currenttime);
    return
  }

  animbutterfly(elm: HTMLDivElement, h, w) {
    let customease = CustomEase.create("custom", "M0,0,C0,0,0.256,0.014,0.432,0.176,0.608,0.338,0.436,0.638,0.638,0.842,0.792,0.998,1,1,1,1");
    let leftwing = elm.getElementsByClassName('wing')[0];
    let rightwing = elm.getElementsByClassName('wing')[1];
    this.primairytimeline.fromTo(leftwing, { rotationY: -20 }, {
      rotationY: 90, duration: 0.25, transformOrigin: '700% 50%', repeat: -1, yoyo: true,
      ease: customease
    }, 0);
    this.primairytimeline.fromTo(rightwing, { rotationY: 200 }, {
      rotationY: 90, duration: 0.25, repeat: -1, yoyo: true,
      ease: customease
    }, 0);
    let butterfly = elm.getElementsByClassName('butterfly')[0];
    this.primairytimeline.fromTo(butterfly, { y: 0 }, { y: -5, duration: 0.25, repeat: -1, yoyo: true, ease: customease }, 0); //set body animation
  }

  animflies(elm, h, w) {
    let minw = (w * -1) / 2;
    let minh = (h * -1) / 2;
    let movex = '+=' + this.R(minw, (w / 2));
    let movey = '+=' + this.R(minh, (h / 2));
    let scale = this.R(0.2, 1.5);
    this.primairytimeline.to(elm, { duration: this.R(5, 20), scale: scale, ease: 'none', repeat: -1, yoyo: true }, this.R(0, 10));
    this.primairytimeline.to(elm, { duration: this.R(0, 20), autoAlpha: 0.1, ease: 'none', repeat: -1, yoyo: true }, this.R(0, 10));
    this.primairytimeline.to(elm, { duration: this.R(5, 20), y: movey, ease: 'none', repeat: -1, yoyo: true }, 0);
    this.primairytimeline.to(elm, { duration: this.R(5, 20), x: movex, rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'none' }, 0);
  }


  animstars(elm) {
    let scale = this.R(0.2, 1.5);
    this.primairytimeline.to(elm, { duration: 10, scale: scale, ease: 'none', repeat: -1, yoyo: true, delay: this.R(0, 10) }, this.R(0, 10));
    this.primairytimeline.to(elm, { duration: 5, autoAlpha: 0, ease: 'none', repeat: -1, yoyo: true, delay: this.R(0, 10) }, this.R(0, 10));
  }

  animsun(elm, h, w) {
    this.primairytimeline.to(elm, { duration: 10, y: h, x: w, ease: 'linear.none', repeat: -1, delay: 0 }, this.R(0, 10));
  }

  animclouds(elm, h, w) {
    this.primairytimeline.to(elm, { duration: 15, x: '+=200', ease: 'linear.none', repeat: -1, delay: 0 }, 0);
  } // y: h, '+=' + w

  animsnow(elm, h) {
    let randomstart = this.R(0, 30)
    this.primairytimeline.to(elm, { duration: this.R(15, 30), y: h + 100, ease: 'linear.none', repeat: -1, delay: 0 }, randomstart);
    this.primairytimeline.to(elm, { duration: this.R(8, 8), x: '+=100', rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
    this.primairytimeline.to(elm, { duration: this.R(2, 8), rotationX: this.R(0, 360), rotationY: this.R(0, 360), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
  };

  animceleb(elm, h, w) {
    let minw = (w * -1) / 2;
    let movex = '+=' + this.R(minw, (w / 2));
    let randomstart = this.R(0, 25)
    this.primairytimeline.to(elm, { duration: this.R(20, 30), y: h + 100, ease: 'linear.none', repeat: -1, delay: 0 }, randomstart);
    this.primairytimeline.to(elm, { duration: this.R(8, 15), x: movex, rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
    this.primairytimeline.to(elm, { duration: this.R(8, 15), rotationX: this.R(0, 360), rotationY: this.R(0, 360), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, randomstart);
  };

  // element, time(speed),
  animrain(elm, h) {
    this.primairytimeline.to(elm, { duration: this.R(2, 4), y: h, x: '+=100', ease: 'linear.none', repeat: -1, delay: 0 }, 0);
  };

  animleaves(elm, h) {
    this.primairytimeline.to(elm, { duration: this.R(20, 30), y: h + 100, ease: 'linear.none', repeat: -1, delay: 0 }, 0);
    this.primairytimeline.to(elm, { duration: this.R(4, 8), x: '+=100', rotationZ: this.R(0, 180), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, 0);
    this.primairytimeline.to(elm, { duration: this.R(2, 8), rotationX: this.R(0, 360), rotationY: this.R(0, 360), repeat: -1, yoyo: true, ease: 'sine.out', delay: 0 }, 0);
  }


  R(min, max) { return min + Math.random() * (max - min) };

  RandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
  }



  setDrawAni(from, animation) {
    let animationdrawto = animation.fillleft + ' ' + animation.fillright;
    let animationdrawfrom = animation.drawleft + ' ' + animation.drawright;
    let hideelement = 0;
    //let ease = animation.easetype;
    let ease = this.selectEaseType(animation.easetype);

    if (animation.hideimage === true) {
      hideelement = 0;
    } else { hideelement = 1 }

    let fromset =
    {
      drawSVG: animationdrawfrom,
      duration: animation.duration,
      repeat: animation.repeat,
      stroke: animation.drawcolor,
      strokeWidth: animation.linethickness,
      'fill-opacity': hideelement,
      ease: ease,
      yoyo: animation.yoyo,
      attr: undefined
    };

    let toset =
    {
      drawSVG: animationdrawto,
      duration: animation.duration,
      repeat: animation.repeat,
      stroke: animation.drawcolor,
      strokeWidth: animation.linethickness,
      'fill-opacity': hideelement,
      ease: ease,
      yoyo: animation.yoyo,
      attr: undefined
    };
    if (animation.tor && animation.fromr) { toset.attr = { r: animation.tor }; fromset.attr = { r: animation.fromr } }
    this.primairytimeline.fromTo(from, fromset, toset, animation.start_time);

    return
  }


  async playFunc() {
    // console.log('time play', this.counter, this.currenttime)
    if (this.currenttime < this.counter) {
      // this.vectorService.editsvg = false; // reset outerwise animation won't work
      console.log('play'); // !! dont remove need to read output from console to start video recording

      await new Promise(resolve => setTimeout(resolve, 400));
      if (this.canvas.audio && this.remote) {
        this.audioService.playSound('canvassound', null, this.canvas.loop);
        this.primairytimeline.eventCallback("onComplete", this.audioService.stopSound, ['canvassound', null]);
      }
      // this.TimelinesmallSetter(); // timeline animation
      // clean up for play
      // this.selectedVecPath = false; !! is object not boolean??
      clearTimeout(this.t); //to make sure there is no second loop
      this.playing = true;
      if (this.canvas.videourl && this.canvas.weather !== 'glitch') {
        this.videoPlayer.muted = true;
        this.videoPlayer.play();
      }
      if (this.canvas.loop) {
        this.videoPlayer.loop = true;
      }

      if (this.currenttime === 0) {
        this.primairytimeline.play(0, true);
      } else {
        this.primairytimeline.resume();
      }
      this.t = setInterval(() => { this.incrementSeconds() }, 100);
    }
  }

  stopFunc() {
    console.log('stop')
    // let timelinesetter = document.getElementById('timelinesmallsetter');//timebar bottom
    clearTimeout(this.t);
    this.primairytimeline.pause(0, false);
    this.currenttime = 0;
    //this.primairytimeline.set(timelinesetter, { x: 0 }, 0) //timebar bottom
    this.primairytimeline.timeScale(1);
    this.playing = false;
    if (this.canvas.videourl) {
      this.videoPlayer.pause();
      this.videoPlayer.currentTime = 0;
    }
    // this.detectchange(); remove seperate render operation
    if (this.canvas.audio) {
      this.audioService.stopSound('canvassound', null);
      this.audioService.stopAll();
    }

  }

  pauseFunc() {
    console.log('pause')
    this.primairytimeline.pause();
    if (this.canvas.videourl) {
      this.videoPlayer.pause();
    }
    clearTimeout(this.t);

    if (this.canvas.audio) {
      this.pauseSound('canvassound', null);
    }
  }

  setReplay() {
    if (this.setreplay === true) {
      this.setreplay = false;
    } else {
      this.setreplay = true;
    }
  }

  reverseFunc() {
    clearTimeout(this.t);
    this.t = setInterval(() => { this.deleteSeconds() }, 100);
    this.primairytimeline.reverse();
    if (this.canvas.videourl) {
      this.videoPlayer.playbackRate = 1.0;
    }
  }

  fastforwardFunc() {
    this.primairytimeline.timeScale(5);
  }

  incrementSeconds() {
    if (this.currenttime < (this.counter - 0.1)) {
      this.currenttime = this.currenttime + 0.1;
    } else {
      if (this.setreplay === false) {
        this.pauseFunc();
      } else {
        this.currenttime = 0;
        this.stopFunc();
        this.playFunc();
      }
    }
  }

  deleteSeconds() {
    if (this.currenttime > 0) {
      this.currenttime = this.currenttime - 0.1;
    }
  }


  // do not change adds a eventlistener different from main webapp 
  onchangevideo(): Promise<void> {
    return new Promise(async (fullfill) => {
      if (this.canvas.videourl) { this.canvas['background-color'] = 'transparent' }
      //this.videoPlayer = document.getElementById('videoplayer') as HTMLVideoElement;
      let newvideo = this.canvas.videourl;
      this.http.get(newvideo, { responseType: 'blob' }).subscribe(blob => {
        let urlCreator = window.URL;
        //let vid = urlCreator.createObjectURL(blob);
        //this.canvas.videourl = this.sanitizer.bypassSecurityTrustUrl(vid);
        this.videourl = urlCreator.createObjectURL(blob);
       // this.canvas.videourl = event;
        fullfill();
      })
    });
  }




  public startStop() {
    //if (this.currenttime >= this.counter) {
    this.stopFunc();
    //}
    //setTimeout(() => {
    this.playFunc();
    //}, 500);
  }


}
