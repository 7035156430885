<!-- START VIDEO SCREEN ****************_______________________________ -->
<div [style.width]="canvas.width" [style.height]="canvas.height" class="template-container-main" [style.top]="top"
    [style.left]="left" style="position: relative; overflow: hidden;">

    <div style="height: 100%; width: 100%; position: absolute; pointer-events: none; background-color: white; z-index: 1000"
        *ngIf="remote && busy">
        <img src="assets/enydea-wait-animation.gif" alt="loading.." style="margin:auto; width: 150px; display: block; position: relative;
        top: 50%;
        transform: translateY(-50%);">
    </div>
    <!-- *ngIf="changevideo" -->
    <div [attr.id]="'videobox'" style="position: relative;" [class.starbackground]="canvas.weather === 'stars'"
        [style.transform]="'scale('+zoomfactor+')'" [style]="canvas">
        <!-- [style.overflow]="overflow" -->



        <img *ngIf="setreplay !== true && remote === true" (click)="startStop()" class="startstop"
            src="assets/play_arrow-black-18dp.svg">
        <!-- TEST EDIT OUT PUT BACK FOR DLCR!> overflow:hidden; -->

        <div [style.background-image]="canvas['background-image-gradient']"
            style="height: 100%; width: 100%; position: absolute; pointer-events: none;"></div>

        <!-- selectedVecPath &&  -->
        <video *ngIf="canvas.videourl" class="video-background" no-controls [src]="videourl | safe: 'url'" #videoPlayer
            (click)="deSelectAll()" id="videobgcontainer" muted=true>
        </video>

        <audio *ngIf="canvas.audio" [attr.id]="'canvassound'">
            <source type="audio/mp3" [src]="canvas.audio">
        </audio>

        <img *ngIf="canvas.videourl === '' && canvas.imageurl" id="imagebgcontainer"
            style="position: absolute; pointer-events: none;" src={{canvas.imageurl}} [style.width]="canvas.width"
            [style.height]="canvas.height">


        <div style="height: 100%; width: 100%; position: absolute; z-index: 1000; pointer-events: none;"
            id="weathercontainer"></div>

        <!-- Stars -->
        <div style="height: 100%; width: 100%; position: absolute; pointer-events: none;" id="starscontainer">
        </div>


        <div *ngIf="animationarray && changenow" #myBounds id="myBounds"
            style="overflow: hidden; background-color: transparent;">

            <div id="threedcontainer" style="width: 100%; height: 100%; position: absolute;">
                <app-threed [primairytimeline]="primairytimeline" [account]="Account" [option]="option"
                    [systembusy]="systembusy" [canvas]="canvas" [selectedelement]="selectedelement"
                    [globalThreedControls]="globalThreedControls" [animationarray]="animationarray"
                    [selectanimationindex]="selectanimationindex">
                </app-threed>
            </div>


            <div *ngFor="let element of animationarray; let i = index">
                <!-- [ngClass]="element.onhoveraction" interferes with transform position system -->

                <div *ngFor="let motion of element.motionpath; let im = index;">
                    <div class="path-edit" [id]="element.id + '-' + im" [innerHTML]="motion | safeHtml"
                        [style.width]="canvas.width" [style.height]="canvas.height"
                        [ngStyle]="{'z-index':editpath === true && im === selectanimationindex ? 1000 : -1 }">
                    </div>
                </div>

                <div *ngIf="element.type === 'image' && !element.groupmember" id="{{element.id}}" [attr.id]="element.id"
                    [ngStyle]="element.style" [style.width]="element.style.width" [style.height]="element.style.height"
                    class="elementanimationarray" matTooltip="{{element.onhovershow}}" (click)="navigateOut(element)">

                    <div class="whiteboard">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                            [attr.id]="element.id + 'crop'" width="100%" height="100%"
                            style="fill:transparent; stroke:black; stroke-width: 2px;" viewBox="0 0 500 500">
                            <defs>
                                <!-- d={{element.clippath}}  d="M100,100 L150,100 L150,150 z" -->
                                <clipPath [attr.id]="element.id + 'cropclip'">
                                    <path [attr.d]="element.clippath" [attr.id]="element.id + 'croppath'" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <img *ngIf="element.src && element.cliparray.length === 0" style="width: 100%; height: 100%;"
                        [ngStyle]="{'opacity': element.opacity}" src={{element.src}} [class.imggreyclass]="element.grey"
                        [class.imgblur]="element.blur">
                    <div *ngIf="element.cliparray.length > 0">
                        <div *ngFor="let clippart of element.cliparray"
                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                            <img src={{element.src}} [ngStyle]="clippart.style" id="{{clippart.id}}">
                        </div>
                    </div>
                </div>

                <div *ngIf="element.type === 'embeddedvideo' && !element.groupmember" id="{{element.id}}"
                    [attr.id]="element.id" [ngStyle]="element.style" [style.width]="element.style.width"
                    [style.height]="element.style.height" class="elementanimationarray">

                    <!-- edit path crop/clip -->
                    <div [ngStyle]="{'z-index':cropimages === true && element === selectedelement ? 1000 : -1 }"
                        class="cropboard">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                            [attr.id]="element.id + 'crop'" width="100%" height="100%"
                            style="fill:transparent; stroke:black; stroke-width: 2px;" viewBox="0 0 500 500">
                            <defs>
                                <!-- d={{element.clippath}}  d="M100,100 L150,100 L150,150 z" -->
                                <clipPath [attr.id]="element.id + 'cropclip'">
                                    <path [attr.d]="element.clippath" [attr.id]="element.id + 'croppath'" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <canvas style="width: 100%; height: 100%;" [class.hide]="!playing"
                        [attr.id]="element.id + 'canvaseffectlayer'" *ngIf="element.effect"></canvas>

                    <video [src]="element.blobsrc" [class.hide]="element.effect && playing"
                        *ngIf="element.blobsrc && element.cliparray.length == 0" style="width: 100%; height: 100%;"
                        [class.imggreyclass]="element.grey" [class.imgblur]="element.blur"
                        [style.clip-path]="element['clip-path']" muted=true></video>

                    <div *ngIf="element.cliparray.length > 0">
                        <div *ngFor="let clippart of element.cliparray"
                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                            <img [src]="element.blobsrc" [ngStyle]="clippart.style"
                                [style.clip-path]="element.style['clip-path']" id="{{clippart.id}}">
                        </div>
                    </div>

                    <mat-icon *ngIf="element === selectedelement" class="iconsize-4" class="rotatehandle"
                        [attr.id]="element.id + 'rotatehandle'">replay
                    </mat-icon>
                </div>



                <p *ngIf="element.type === 'text' && !edittext && !element.groupmember" id="{{element.id}}"
                    [attr.id]="element.id" [ngStyle]="element.style" class="elementanimationarray"
                    matTooltip="{{element.onhovershow}}" (click)="navigateOut(element)">
                    {{element.content}} </p>

                <mat-form-field appearance="outline"
                    *ngIf="element.type === 'text' && edittext && element === selectedelement && !element.groupmember"
                    id="{{element.id}}" [attr.id]="element.id" [ngStyle]="element.style" class="elementanimationarray">
                    <textarea matInput [(ngModel)]="element.content" style="width: 100%; height: 100%;"></textarea>
                </mat-form-field>

                <!-- https://stackoverflow.com/questions/43511437/how-to-use-ng2-ckeditor-as-inline -->

                <div *ngIf="element.type === 'shape' && !element.groupmember" id="{{element.id}}"
                    [style.width]="element.style.width" [style.height]="element.style.height"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                    [class]='element.style.class' [ngStyle]="element.style" matTooltip="{{element.onhovershow}}"
                    (click)="navigateOut(element)">
                </div>

                <div *ngIf="element.type === 'form' && !element.groupmember" id="{{element.id}}"
                    [style.width]="element.style.width" [style.height]="element.style.height" [ngStyle]="element.style">
                    <iframe
                        style="border:0; height:100%; left:0; position:absolute; top:0; width:100%; pointer-events: none;"
                        scrolling="no" frameborder="0" allowfullscreen
                        [src]="element.formsrc | safe: 'resourceUrl'"></iframe>

                </div>

                <div *ngIf="element.type === 'counter' && !element.groupmember" id="{{element.id}}"
                    [style.width]="element.style.width" [style.height]="element.style.height"
                    [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                    [class]='element.style.class' [ngStyle]="element.style" matTooltip="{{element.onhovershow}}"
                    (click)="navigateOut(element)">

                    <div *ngFor="let counteranim of element.countertype; let c = index;" class="counter-animation"
                        [style.width]="element.style.width" [style.height]="element.style.height">
                        <svg width="100%" height="100%" viewBox="0 0 240 240">
                            <circle *ngIf="counteranim.counterstyle == 'circle'"
                                [attr.id]="element.id + 'svgcircle' + c" fill="transparent" r="100" cx="120" cy="120" />
                            <rect *ngIf="counteranim.counterstyle == 'square'" width="240" height="240"
                                [attr.id]="element.id + 'svgcircle' + c" fill="transparent" />
                            <line *ngIf="counteranim.counterstyle == 'linear'" x1="120" y1="240" x2="120" y2="0"
                                [attr.id]="element.id + 'svgcircle' + c" fill="transparent" />
                        </svg>
                        <!-- [stroke]="countertype.drawcolor" [stroke-width]="countertype.linethickness" -->
                    </div>
                    <div class="counter-container" [attr.id]="element.id + 'num'"
                        [style.line-height]="element.style.height"></div>
                    <mat-icon *ngIf="element === selectedelement" class="iconsize-4" class="rotatehandle"
                        [attr.id]="element.id + 'rotatehandle'">replay
                    </mat-icon>
                </div>
                <!--   [style.transform]="'translate(' + element.posx + 'px, ' + element.posy +'px) rotate(' + element.rotation + 'deg)' | safe: 'style'"                    -->
                <div *ngIf="element.type === 'chart' && !element.groupmember" [style.width]="element.style.width"
                    [style.height]="element.style.height" [ngStyle]="element.style" class="elementanimationarray"
                    matTooltip="{{element.onhovershow}}" (click)="navigateOut(element)" id="{{element.id}}">
                    <canvas baseChart width="element.style.width" height="element.style.height"
                        [datasets]="element.productiondata" [labels]="element.label" [colors]="element.colors"
                        [legend]="element.legend" [options]="element.lineChartOptions" [chartType]="element.charttype"
                        [attr.id]="'chart' + element.id" (chartClick)="chartClicked($event, this)">
                    </canvas>
                </div>
                <div *ngIf="element.type === 'vector' && !element.groupmember" [style.width]="element.style.width"
                    style.filter="none" [style.height]="element.style.height" [ngStyle]="element.style"
                    id="{{element.id}}" class="elementanimationarray"
                    [class.elementanimationarrayselected]="element === selectedelement"
                    matTooltip="{{element.onhovershow}}" (click)="navigateOut(element)">
                    <div style="height: 100%; width: 100%; cursor: pointer;" [innerHTML]="element.svgcombi | safeHtml"
                        (click)="vectorService.clickVectorPaths($event, element)">
                    </div>
                </div>


                <!-- __________________________________Start Combibox  (put in on own component)?_________________________________ -->

                <div *ngIf="element.type === 'vectorcombi'" [style.width]="element.style.width"
                    [style.height]="element.style.height" id="{{element.id}}" [ngStyle]="element.style"
                    [ngClass]="{elementanimationarrayselected: element === selectedelement, editcombi: vectorcombiedit, elementanimationarray: true}">
                    <div *ngFor="let part of element.vectors; let idy = index;">
                        <!-- <div *ngIf="part.motionpath" class="path-edit" [innerHTML]="part.motionpath | safeHtml"
        [style.width]="canvas.width" [style.height]="canvas.height"
        [ngStyle]="{'z-index':editpath === true && element === selectedelement ? 1000 : -1 }">
    </div> -->
                        <div *ngIf="part.type === 'image' && part.groupmember" [attr.id]="part.id"
                            [ngStyle]="part.style" [style.width]="part.style.width" [style.height]="part.style.height"
                            class="elementanimationarray"
                            [class.elementanimationarrayselected]="part === selectedelement && vectorcombiedit">
                            <div [ngStyle]="{'z-index':cropimages === true && part === selectedelement ? 1000 : -1 }"
                                class="whiteboard">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" [attr.id]="part.id + 'crop'" width="100%" height="100%"
                                    style="fill:transparent; stroke:black; stroke-width: 2px;" viewBox="0 0 500 500">
                                    <defs>
                                        <clipPath [attr.id]="part.id + 'cropclip'">
                                            <path [attr.d]="part.clippath" [attr.id]="part.id + 'croppath'" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <img *ngIf="part.src && part.cliparray.length === 0" style="width: 100%; height: 100%;"
                                [ngStyle]="{'opacity': part.opacity}" src={{part.src}} [class.imggreyclass]="part.grey"
                                [class.imgblur]="part.blur">
                            <div *ngIf="part.cliparray.length > 0">
                                <div *ngFor="let clippart of part.cliparray"
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%">
                                    <img src={{part.src}} [ngStyle]="clippart.style" id="{{clippart.id}}">
                                </div>
                            </div>
                        </div>
                        <label (dblclick)="setEditText()" [ngResizable]="part === selectedelement"
                            *ngIf="part.type === 'text' && !edittext && part.groupmember" id="{{part.id}}"
                            [attr.id]="part.id" [ngStyle]="part.style" class="elementanimationarray"
                            [class.elementanimationarrayselected]="part === selectedelement">{{part.content}}
                        </label>
                        <mat-form-field appearance="outline"
                            *ngIf="part.type === 'text' && edittext && part === selectedelement && part.groupmember"
                            id="{{part.id}}" [attr.id]="part.id" [ngStyle]="part.style" class="elementanimationarray">
                            <textarea matInput [(ngModel)]="part.content" style="width: 100%; height: 100%;"></textarea>

                        </mat-form-field>
                        <div *ngIf="part.type === 'shape' && part.groupmember" id="{{part.id}}"
                            [style.width]="part.style.width" [style.height]="part.style.height"
                            [ngResizable]="part === selectedelement"
                            [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                            [class]='part.style.class' [ngStyle]="part.style">

                        </div>
                        <div *ngIf="part.type === 'counter' && !part.groupmember" id="{{part.id}}"
                            [style.width]="part.style.width" [style.height]="part.style.height"
                            [ngResizable]="part === selectedelement"
                            [ngClass]="{'elementanimationarray':true, elementanimationarrayselected: element === selectedelement }"
                            [class]='part.style.class' [ngStyle]="part.style">

                            <div *ngFor="let counteranim of part.countertype; let c = index;" class="counter-animation"
                                [style.width]="part.style.width" [style.height]="part.style.height">
                                <svg width="100%" height="100%" viewBox="0 0 240 240">
                                    <circle *ngIf="counteranim.counterstyle == 'circle'"
                                        [attr.id]="part.id + 'svgcircle' + c" fill="transparent" r="100" cx="120"
                                        cy="120" />
                                    <rect *ngIf="counteranim.counterstyle == 'square'" width="240" height="240"
                                        [attr.id]="part.id + 'svgcircle' + c" fill="transparent" />
                                    <line *ngIf="counteranim.counterstyle == 'linear'" x1="120" y1="240" x2="120" y2="0"
                                        [attr.id]="part.id + 'svgcircle' + c" fill="transparent" />
                                </svg>
                                <!-- [stroke]="countertype.drawcolor" [stroke-width]="countertype.linethickness" -->
                            </div>
                            <div *ngIf="part.type === 'chart' && part.groupmember" [style.width]="part.style.width"
                                [style.height]="part.style.height" [ngStyle]="part.style" class="elementanimationarray"
                                [class.elementanimationarrayselected]="part === selectedelement" id="{{part.id}}">
                                <canvas baseChart width="part.style.width" height="part.style.height"
                                    [datasets]="part.productiondata" [labels]="part.label" [colors]="part.colors"
                                    [legend]="part.legend" [options]="part.lineChartOptions"
                                    [chartType]="part.charttype" [attr.id]="'chart' + part.id">
                                </canvas>

                            </div>
                            <div *ngIf="part.type === 'vector' && part.groupmember" [style.width]="part.style.width"
                                [style.height]="part.style.height" [ngStyle]="part.style" id="{{part.id}}"
                                class="elementanimationarray"
                                [ngStyle]="{'z-index':vectorcombiedit === true && element === selectedelement ? 1000 : -1 }"
                                [class.elementanimationarrayselected]="part === selectedelement && vectorcombiedit">
                                <div style="height: 100%; width: 100%; cursor: pointer;"
                                    [innerHTML]="part.svgcombi | safeHtml"
                                    (click)="vectorService.clickVectorPaths($event, element)">
                                </div>

                            </div>
                        </div>
                        <div *ngIf="remote">
                            <div *ngFor="let partpart of part.animation; let i = index;">
                                <audio *ngIf="partpart.audioeffectsrc" [attr.id]="part.id + i + 's'">
                                    <source type="audio/mp3" [src]="partpart.audioeffectsrc">
                                </audio>
                            </div>
                        </div>


                    </div>

                </div>
                <!-- style="overflow: hidden;"  -->
                <!-- not visible object to connect sound to  -->
                <div *ngIf="remote">
                    <div *ngFor="let part of element.animation; let i = index;">
                        <audio *ngIf="part.audioeffectsrc" [attr.id]="element.id + i + 's'">
                            <source type="audio/mp3" [src]="part.audioeffectsrc">
                        </audio>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END VIDEO SCREEN ****************_______________________________ -->