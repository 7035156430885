// export const environment = {
//   production: true,
//   BASE_URL: 'https://dlcr.enydea.com',
//   API_VERSION: 'api'
// };

export const environment = {
  production: true,
  envName : 'prod',
  // apiUrl : '//dlcr.enydea.com',
  // apiVersion : 'api',
  //BASE_URL: 'https://chat.enydea.com',
  BASE_URL: 'https://dlcr.enydea.com',
  API_VERSION: 'api',
  recaptcha: {
    siteKey: '6Le5UFQlAAAAAMI3Ki8_AnE-2oM3gDHO6-5yQQ_J'
  }
};

