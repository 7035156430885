import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { BASE_URL } from './shared/base.api';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private host = BASE_URL;

  constructor(
    private http: HttpClient
  ) { }

  sendToken(token: string) {
    return this.http.post<any>(this.host + "/api/formsends/tokenvalidate", { recaptcha: token })
  }

  sendForm(form: object, formobject: object) {
    return this.http.post<any>(this.host + "/api/formsends/send", { formvalues: form, formobject: formobject })
  }

  getForm(id: string) {
    //return this.http.get<any>("https://api.enydea.com/api/forms/" + id, {});
    //return this.http.get<any>("https://localhost:443/api/formsend/getform/" + id, {});
    //return this.http.get<any>(this.host + "/api/formsends/getform/" + id, {});
// "https://dlcr.xbms.io/api/formsends/getform/" + id
    return this.http.get<any>(this.host + "/api/formsends/getform/" + id)
      .pipe(
        catchError(this.handleError<any>('getForm', []))
      )

  }

  getChat(id: string) {
    //return this.http.get<any>("https://api.enydea.com/api/forms/" + id, {});
    //return this.http.get<any>("https://localhost:443/api/formsend/getform/" + id, {});
    //return this.http.get<any>(this.host + "/api/formsends/getform/" + id, {});

    return this.http.get<any>(this.host + "/api/chatsends/getchats/" + id)
      .pipe(
        catchError(this.handleError<any>('getForm', []))
      )

  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}

