import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
    BASE_URL,
    API_VERSION,
} from './shared/base.api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor(

      
        ) {


    }

}