import { Injectable } from '@angular/core';
import { CustomBounce, gsap } from 'src/assets/js/all';
import {
  chart, animationtype, vectoranimationtype, vectoranimation, vectorcombinator, vectorelement,
  splittexttype, shapeanimation, imageanimation, textanimation, counteranimation, countertype, formanimation, threedanimation, threedanimationcontrol
} from '../videocreator.model';
import { EasetypesService } from './easetypes.service';
import { AudioService } from './audio.service';
import { P } from '@angular/cdk/keycodes';


@Injectable({
  providedIn: 'root'
})
export class AnimationsService {

  constructor(
    private audioService: AudioService,
    private easetypesServices: EasetypesService,
  ) { }


  addAnimation(iset, element: animationtype, elementA, i: number, canvas, primairytimeline) {
    // iset = id node element to animate
    // element = animation from animationarray
    // elementA = base element object
    let duration = element.duration;
    let starttime = element.start_time;
    let endtime = element.end_time;
    let anitype = element.anim_type;
    let rotationcycle = element.rotationcycle;
    let scalesize = element.scalesize;
    let skewY = element.skewY;
    let skewX = element.skewX;
    let aniset;
    let ease = this.easetypesServices.selectEaseType(element.easetype);
    let repeat = element.repeat;

    if (anitype == 'shatter') {
      elementA: imageanimation;
      elementA.cliparray = [];
      let widthpart = 50;
      let heightpart = 50;

      if (elementA.style.height === 'auto' || elementA.style.height === undefined) {
        let curimg = document.getElementById(elementA.id);
        elementA.style.height = curimg.clientHeight + 'px';
      }

      if (elementA.style.width === 'auto' || elementA.style.width === undefined) {
        let curimg = document.getElementById(elementA.id);
        elementA.style.width = curimg.clientWidth + 'px';
      }

      let heigthcount = Math.ceil(parseInt(elementA.style.height) / heightpart);
      let widthcount = Math.ceil(parseInt(elementA.style.width) / widthpart);
      //console.log(widthcount, elementA.style.width);

      for (let k = 0; k < heigthcount; k++) {
        for (let i = 0; i < widthcount; i++) {
          let fromtop = k * heightpart;
          let fromleft = i * widthpart;
          let fromright = parseInt(elementA.style.width) - (fromleft + widthpart);
          let bottom = parseInt(elementA.style.height) - (fromtop + heightpart);
          // this.sanetizer
          elementA.cliparray.push({
            style: {
              'clip-path': 'inset(' + fromtop + 'px ' + fromright + 'px ' + bottom + 'px ' + fromleft + 'px)',
              width: '100%',
              height: '100%'
            },
            id: elementA.id + i + k
          });
        }
      }
    }

    if (anitype == 'fly-in') {
      let h = parseInt(canvas.height + 100);
      let w = parseInt(canvas.width + 100);
      let movex = this.R((w * -1), (w));
      let movey = this.R((h * -1), (h));
      primairytimeline.from(iset, { duration: duration, rotationX: element.transformOriginX, rotationY: element.transformOriginY, ease: 'none', repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay, }, starttime);
      primairytimeline.from(iset, { duration: duration, y: movey, ease: 'none', repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay, }, starttime);
      primairytimeline.from(iset, { duration: duration, x: movex, rotationZ: this.R(0, 180), repeat: repeat, yoyo: element.yoyo, ease: 'none',  repeatDelay: element.delay, }, starttime);
    }

    if (anitype == 'shatter') {
      setTimeout(() => {

        for (let i = 0; i < elementA.cliparray.length; i++) {
          let shatsection = document.getElementById(elementA.cliparray[i].id);
          let h = parseInt(canvas.height + 100);
          let w = parseInt(canvas.width + 100);
          let minw = (w * -1) / 2;
          let minh = (h * -1) / 2;
          // let movex = '+=' + this.R(minw, (w / 2));
          // let movey = '+=' + this.R(minh, (h / 2));
          let movex = this.R((w * -1), (w));
          let movey = this.R((h * -1), (h));
          let scale = this.R(0.2, 1.5);

          if (element.fromto === 'to') {
            primairytimeline.to(shatsection, { duration: duration, rotationX: element.transformOriginX, rotationY: element.transformOriginY, ease: 'none', repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay, }, starttime);
            primairytimeline.to(shatsection, { duration: duration, y: movey, ease: 'none', repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay, }, starttime);
            primairytimeline.to(shatsection, { duration: duration, x: movex, rotationZ: this.R(0, 180), repeat: repeat, yoyo: element.yoyo, ease: 'none', repeatDelay: element.delay, }, starttime);
          }
          if (element.fromto === 'from') {
            primairytimeline.from(shatsection, { duration: duration, rotationX: element.transformOriginX, rotationY: element.transformOriginY, ease: 'none', repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay, }, starttime);
            primairytimeline.from(shatsection, { duration: duration, y: movey, ease: 'none', repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay, }, starttime);
            primairytimeline.from(shatsection, { duration: duration, x: movex, rotationZ: this.R(0, 180), repeat: repeat, yoyo: element.yoyo, ease: 'none', repeatDelay: element.delay, }, starttime);
          }
        }
      }, 500);
    }

    if (element.audioeffectsrc !== '') {
      primairytimeline.call(this.audioService.playSound, [elementA.id + i + 's', element.audioeffectsrc, false], starttime);
      primairytimeline.call(this.audioService.stopSound, [elementA.id + i + 's', element.audioeffectsrc, false], endtime);
      primairytimeline.eventCallback("onInterrupt", this.audioService.pauseSound, [elementA.id + i + 's', element.audioeffectsrc]);
    }

    if (anitype === 'rotation') {
      let orgin = element.transformOriginX + ' ' + element.transformOriginY
      aniset = {
        duration: duration, rotation: rotationcycle,
        ease: ease, transformOrigin: orgin,
        repeatDelay: element.delay,
        repeat: repeat, yoyo: element.yoyo
      }
    }
    if (anitype === 'scale') {
      aniset = {
        duration: duration,
        scale: scalesize, ease: ease, repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
      }
    }
    if (anitype === 'appear') {
      aniset = {
        duration: duration,
        autoAlpha: 0, ease: ease, repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
      };
    }

    if (anitype === 'bounce') {
      //Create a custom bounce ease:
      CustomBounce.create("myBounce", { strength: 0.6, squash: 3, squashID: "myBounce-squash" });
      let orgin = element.transformOriginX + ' ' + element.transformOriginY;
      let rotate;
      if (!element.rotationkeeppos) {
        gsap.set(iset, { xPercent: element.travellocX, yPercent: element.travellocY, transformOrigin: orgin, autoAlpha: 1 }); // tranformorgin to set offset??
        rotate = element.rotationcycle
      } else {
        gsap.set(iset, { xPercent: element.travellocX, yPercent: element.travellocY, autoAlpha: 1 }); // tranformorgin to set offset??
        rotate = false;
      }
      //let nr = i - 1;
      let moveid = elementA.id + '-' + i;
      let svgset = document.getElementById(moveid + 'p'); 
      if (!svgset){console.log('move svgset missing'); return;}
      //do the bounce by affecting the "y" property.
      primairytimeline.from(iset, {
        duration: duration,
        //y:550,
        repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
        motionPath: {
          path: svgset,
          // autoRotate: rotate,
          // align: svgset//'self'
        },
        ease: "myBounce"
      }, starttime);

      //and do the squash/stretch at the same time:
      primairytimeline.to(iset, { duration: duration, scaleY: 0.5, scaleX: 1.3, ease: "myBounce-squash", transformOrigin: "center bottom" }, starttime);
    }

    if (anitype === 'move') {
      // let nr: number = i-1;
      // console.log(nr)
      let moveid = elementA.id + '-' + i;
      let svgset = document.getElementById(moveid + 'p'); 
      if (!svgset){console.log('move svgset missing: ' + moveid + 'p'); return;}
      let orgin = element.transformOriginX + ' ' + element.transformOriginY;

      let rotate;
      if (!element.rotationkeeppos) {
        gsap.set(iset, { xPercent: element.travellocX, yPercent: element.travellocY, transformOrigin: orgin, autoAlpha: 1 }); // tranformorgin to set offset??
        rotate = element.rotationcycle
      } else {
        gsap.set(iset, { xPercent: element.travellocX, yPercent: element.travellocY, autoAlpha: 1 }); // tranformorgin to set offset??
        rotate = false;
      }

      aniset = {
        duration: duration,
        ease: ease,
        repeat: repeat,
        yoyo: element.yoyo, repeatDelay: element.delay,
        immediateRender: true,
        motionPath: {
          path: svgset,
          autoRotate: rotate,
          align: svgset//'self'
        }
      }
    }

    if (anitype === 'skew') {
      aniset = {
        duration: duration,
        skewY: skewY, skewX: skewX, ease: ease, repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
      }
    }

    if (anitype === '3drotate') {
      aniset = {
        duration: duration,
        rotationX: element.transformOriginX, rotationY: element.transformOriginY, ease: ease, repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
      }
    }

    // run primairy animations
    if (anitype !== 'fountain' && anitype !== 'followminions' && anitype !== 'bounce' && anitype !== 'shatter' && anitype !== 'fly-in') {
      if (element.fromto === 'from') {
        primairytimeline.from(iset, aniset, starttime);
      }
      if (element.fromto === 'to') {
        primairytimeline.to(iset, aniset, starttime);
      }
      if (elementA.cliparray > 0) {
        for (let i = 0; i < elementA.cliparray.length; i++) {
          let idsetshat = document.getElementById(elementA.cliparray[i].id);
          if (element.fromto === 'from') {
            primairytimeline.from(idsetshat, aniset, starttime);
          }
          if (element.fromto === 'to') {
            primairytimeline.to(idsetshat, aniset, starttime);
          }
        }
      }
    }

    if (anitype === 'fountain') {
      let qty = 80;

      


      for (let i = 0; i < qty; i++) {



        let height = parseInt(canvas.height, 10);
        let width = parseInt(canvas.width, 10);
        let distanceH = height - elementA.posy;
        let distanceW = width - elementA.posx;
        let H, W;

        if (distanceH < elementA.posy) {
          H = distanceH;
        } else {
          H = elementA.posy;
        }

        if (distanceW < elementA.posx) {
          W = distanceW;
        } else {
          W = elementA.posx;
        }
        let cln = iset.cloneNode(true);
        let parent = iset.parentElement;
        cln.style.opacity = 0;
        cln.style['pointer-events']= 'none';

        primairytimeline.to(cln, { opacity: 1 },0);
        
        parent.append(cln);
        let delay = Math.random() * duration;
        if (element.fromto === 'from') {
          primairytimeline.from(cln, {
            duration: duration, delay: delay, repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
            physics2D: { velocity: Math.random() * W, angle: Math.random() * 40 + 250, gravity: H }
          }, starttime);
        }
        if (element.fromto === 'to') {
          primairytimeline.to(cln, {
            duration: duration, delay: delay, repeat: repeat, yoyo: element.yoyo, repeatDelay: element.delay,
            physics2D: { velocity: Math.random() * W, angle: Math.random() * 40 + 250, gravity: H }
          }, starttime);

        }
      }
    }

    if (anitype === 'followminions') {
      let minions = 20;
      let seperation = 0.17;

      let moveid = elementA.id + '-' + i;
      let svgset2 = document.getElementById(moveid + 'p'); 

      
      //primairytimeline.to(iset, { autoAlpha: 0}, 0);
      //let svgset2 = document.getElementById(elementA.id + 'p');
      // if (element.fromto === 'from') {
      // primairytimeline.to(iset,
      //   {
      //     duration: duration,
      //     x: elementA.posx,
      //     y: elementA.posy,
      //     ease: ease, repeat: repeat, yoyo: element.yoyo,
      //     motionPath: {
      //       path: svgset2, //'id + p'
      //       autoRotate: 90,
      //       //immediateRender: true
      //     }
      //   }, starttime);
      // } else {
      //   primairytimeline.to(iset,
      //     {
      //       duration: duration,
      //       x: elementA.posx,
      //       y: elementA.posy,
      //       ease: ease, repeat: repeat, yoyo: element.yoyo,
      //       motionPath: {
      //         path: svgset2, //'id + p'
      //         autoRotate: 90,
      //         //immediateRender: true
      //       }
      //     }, starttime);
      // }

      let orgin = element.transformOriginX + ' ' + element.transformOriginY;

      let rotate;

      if (!element.rotationkeeppos) {
        gsap.set(iset, { xPercent: element.travellocX, yPercent: element.travellocY, transformOrigin: orgin, autoAlpha: 1 }); // tranformorgin to set offset??
        rotate = element.rotationcycle
      } else {
        gsap.set(iset, { xPercent: element.travellocX, yPercent: element.travellocY, autoAlpha: 1 }); // tranformorgin to set offset??
        rotate = false;
      }

      for (let i = 0; i < minions; i++) {
        //start creature animation every 0.12 seconds
        let cln = iset.cloneNode(true);
        //console.log(cln);
        let parent = iset.parentElement;
        cln.style.opacity = 0;
        cln.style['pointer-events']= 'none';
        parent.append(cln);

        primairytimeline.to(cln, { opacity: 1 },0);
        

        if (element.fromto === 'from') {
          //primairytimeline.fromTo(cln, { autoAlpha: 0 }, {autoAlpha: 1}, 0);
       
          primairytimeline.from(cln,
            {
              duration: duration,
              x: elementA.posx,
              y: elementA.posy,
              immediateRender: true,
              //xPercent: element.travellocX, yPercent: element.travellocY, transformOrigin: orgin,
              ease: ease, repeat: repeat, yoyo: element.yoyo, delay: i + 1 * seperation,
              repeatDelay: element.delay,
              motionPath: {
                path: svgset2, //'id + p'
                autoRotate: 90,
                align: svgset2
                //immediateRender: true
              }
            }, starttime);
        }

        if (element.fromto === 'to') {
          primairytimeline.to(cln,
            {
              duration: duration,
              x: elementA.posx,
              y: elementA.posy,
              immediateRender: true,
              //xPercent: element.travellocX, yPercent: element.travellocY, transformOrigin: orgin,
              ease: ease, repeat: repeat, yoyo: element.yoyo, delay: i * seperation,
              repeatDelay: element.delay,
              motionPath: {
                path: svgset2, //'id + p'
                autoRotate: 90,
              
                align: svgset2//'self'
                //immediateRender: true
              }
            }, starttime);
        }
      }
    }
  }


  
  R(min, max) { return min + Math.random() * (max - min) };
}
